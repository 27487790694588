import { useNavigate } from 'react-router-dom';
import SvgIcons from '../assets/svg/SvgIcons';
import ButtonWrapper from '../components/form/ButtonWrapper';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className='d-flex flex-column w-100 align-items-center justify-content-center not-found'>
      <SvgIcons iconType='404' />
      <div className='d-flex flex-column align-items-center justify-content-center gap-3 mt-5'>
        <span className='not-found-title'>Oops!! Something went wrong</span>
        <p className='not-found-description'>
          We couldn’t find what you are looking for!
        </p>
      </div>
      <ButtonWrapper
        className='back-to-home mt-4'
        onClick={() => navigate('/')}
      >
        Back to Home
      </ButtonWrapper>
    </div>
  );
};

export default NotFound;
