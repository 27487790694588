import {
  SET_AUDIO_PLAYER,
  CLOSE_AUDIO_PLAYER,
  NEXT_AUDIO,
  PREVIOUS_AUDIO,
  PLAY_AUDIO_TRACK,
  PAUSE_AUDIO_TRACK,
  AUDIO_TRACK_PLAY_TIME,
  SET_AUDIO_AD_PLAYER,
  COMPLETE_AUDIO_AD,
  AUDIO_AD_TRACK_PLAY_TIME,
  SKIP_AUDIO_AD,
  SET_AUDIO_ADS,
} from '../constants/audioPlayer';
import { IAudioPlayer } from '../../types';
import { AudioPlayerActions } from '../types/audioPlayer';
import { getNextAudio, getPrevAudio } from '../utils/indes';

const initialState: IAudioPlayer = {
  episodes: [],
  activeEpisodeId: '',
  audioAds: [],
  activeAudioAdId: '',
  isOpen: false,
  isPlayTrack: false,
  progress: 0,
  adProgress: 0,
  tempEpisodeId: '',
};

// eslint-disable-next-line
const audioPlayer = (state = initialState, action: AudioPlayerActions) => {
  switch (action.type) {
    case SET_AUDIO_PLAYER:
      return {
        ...state,
        episodes: action.payload.episodes,
        activeEpisodeId: action.payload.activeEpisodeId,
        isOpen: action.payload.isOpen,
      };

    case SET_AUDIO_ADS:
      return {
        ...state,
        audioAds: action.payload.audioAds,
      };

    case SET_AUDIO_AD_PLAYER:
      return {
        ...state,
        audioAds: action.payload.audioAds,
        activeAudioAdId: action.payload.activeAudioAdId,
        isOpen: action.payload.isOpen,
        episodes: action.payload.episodes,
        tempEpisodeId: action.payload.tempEpisodeId,
      };

    case COMPLETE_AUDIO_AD:
      return {
        ...state,
        audioAds: state?.audioAds?.map((adType) => ({
          ...adType,
          list: adType?.list?.filter(
            (ad: any) => ad?.uuid !== state?.activeAudioAdId
          ),
        })),
        activeAudioAdId: '',
        activeEpisodeId: state?.tempEpisodeId,
        adProgress: 0,
        isPlayTrack: true,
      };

    case SKIP_AUDIO_AD:
      return {
        ...state,
        audioAds: state?.audioAds?.map((adType) => ({
          ...adType,
          list: adType?.list?.filter(
            (ad: any) => ad?.uuid !== state?.activeAudioAdId
          ),
        })),
        activeAudioAdId: '',
        activeEpisodeId: state?.tempEpisodeId,
        adProgress: 0,
        isPlayTrack: true,
      };

    case CLOSE_AUDIO_PLAYER:
      return {
        ...state,
        isOpen: false,
        isPlayTrack: false,
      };

    case NEXT_AUDIO:
      return {
        ...state,
        activeEpisodeId: getNextAudio(state.episodes, state.activeEpisodeId),
      };

    case PREVIOUS_AUDIO:
      return {
        ...state,
        activeEpisodeId: getPrevAudio(state.episodes, state.activeEpisodeId),
      };

    case PLAY_AUDIO_TRACK:
      return {
        ...state,
        isPlayTrack: true,
      };

    case PAUSE_AUDIO_TRACK:
      return {
        ...state,
        isPlayTrack: false,
      };

    case AUDIO_TRACK_PLAY_TIME:
      return {
        ...state,
        progress: action.payload.value,
      };

    case AUDIO_AD_TRACK_PLAY_TIME:
      return {
        ...state,
        adProgress: action.payload.value,
      };

    default:
      return state;
  }
};

export default audioPlayer;
