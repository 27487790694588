import React from 'react';

const Check = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='36'
    height='36'
    viewBox='0 0 36 36'
    fill='none'
  >
    <circle cx='18' cy='18' r='18' fill='#E2F952' />
    <path
      d='M14.5167 24.015C14.671 24.1535 14.9033 24.1564 15.061 24.0182C18.4999 20.9969 21.6347 17.828 25.375 14.6303C25.6978 14.3543 25.8901 13.9686 25.9169 13.5432C25.9995 12.2212 24.4448 11.3555 23.3883 12.2404C20.1882 14.9166 17.5276 17.344 15.046 19.8529C14.5583 19.3596 13.9059 18.6539 13.1008 17.7482C12.4784 17.0483 11.3552 16.9272 10.5996 17.683C9.93591 18.3464 9.90426 19.4112 10.5269 20.1077C12.0297 21.7872 12.9732 22.6254 14.5167 24.015Z'
      fill='#9244EB'
    />
  </svg>
);

export default Check;
