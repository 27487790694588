export enum EditorActions {
  ADD_AUDIO = 'add-audio',
  REMOVE_AUDIO = 'remove-audio',
  TRIM_AUDIO = 'trim-audio',
  SPLIT_AUDIO = 'split-audio',
  DUPLICATE_AUDIO = 'duplicate-audio',
  RENAME_AUDIO = 'rename-audio',
  CHANGE_SPEED = 'change-speed',
  CHANGE_VOLUME = 'change-volume',
  CHANGE_TRACK = 'change-track',
  UNDO = 'undo',
}

export const PX_PER_SECOND = 20;
export const TIMELINE_NUMBER_GAP = 5;

export const AUDIO_EDITOR_ACTION_MENU_LIST: { label: string; icon: string }[] =
  [
    {
      label: 'Trim',
      icon: 'trim-icon',
    },
    {
      label: 'Split',
      icon: 'scissor',
    },
    {
      label: 'Duplicate',
      icon: 'copy',
    },
    {
      label: 'Rename',
      icon: 'edit-track',
    },
    {
      label: 'Volume',
      icon: 'volume',
    },
    {
      label: 'Speed',
      icon: 'speed',
    },
    {
      label: 'Delete',
      icon: 'delete-track',
    },
  ];
