import React from 'react';

export const FallBackIcon = () => (
  <svg
    viewBox='0 0 536 733'
    height={400}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_128_10941)'>
      <path
        d='M218.381 104.236V76.0567C218.381 45.1443 226.028 24.7036 239.225 13.0951L218.403 25.1409C216.589 26.0372 214.842 27.0428 213.181 28.1578L212.635 28.4638H212.7C197.013 39.351 187.792 57.4962 187.792 91.4473V122.797L218.359 104.236H218.381Z'
        fill='url(#paint1_linear_128_10941)'
      />
      <path
        d='M156.089 161.645C156.089 171.527 159.498 178.5 165.026 181.889C165.2 181.998 168.303 184.053 168.478 184.163C174.246 187.376 170.553 180.074 179.293 175.024C196.903 164.859 211.171 140.243 211.171 120.02C211.171 110.03 220.413 109.942 214.754 106.619C214.601 106.532 210.384 104.28 210.209 104.193C204.463 101.088 196.619 101.657 187.967 106.663C170.357 116.829 156.089 141.445 156.089 161.667V161.645Z'
        fill='url(#paint2_linear_128_10941)'
      />
      <path
        d='M155.5 182.326C155.5 197.039 160.568 207.445 168.827 212.495C169.068 212.648 185.323 221.349 185.564 221.48C194.15 226.29 188.666 215.381 201.688 207.861C227.907 192.711 249.166 156.027 249.166 125.923C249.166 111.035 263.696 111.866 255.284 106.882C255.044 106.75 236.363 96.8471 236.122 96.7159C227.558 92.0813 215.868 92.9339 202.977 100.389C176.759 115.539 155.5 152.223 155.5 182.326Z'
        fill='url(#paint3_linear_128_10941)'
      />
      <path
        d='M170.095 197.28C170.095 214.135 175.907 226.071 185.367 231.843C185.651 232.018 191.922 235.559 192.206 235.712C202.038 241.222 195.745 228.739 210.69 220.103C240.733 202.745 263.303 165.69 263.303 131.192C263.303 114.14 279.034 109.308 273.178 104.783C272.938 104.586 262.778 99.3393 262.516 99.1863C252.706 93.8739 239.312 94.8577 224.542 103.384C194.5 120.742 170.138 162.782 170.138 197.28H170.095Z'
        fill='url(#paint4_linear_128_10941)'
      />
      <path
        d='M270.936 192.51C292.318 155.913 292.533 116.233 271.417 103.881C250.301 91.5298 215.849 111.185 194.468 147.781C173.086 184.378 172.871 224.058 193.987 236.41C215.103 248.761 249.554 229.106 270.936 192.51Z'
        fill='url(#paint5_linear_128_10941)'
      />
      <path
        d='M259.776 187.597C274.477 162.434 274.622 135.15 260.1 126.656C245.578 118.162 221.888 131.674 207.187 156.836C192.486 181.998 192.341 209.282 206.863 217.777C221.385 226.271 245.075 212.759 259.776 187.597Z'
        fill='url(#paint6_linear_128_10941)'
      />
      <path
        d='M163.453 171.789C156.199 183.026 158.362 197.367 168.325 203.794C178.266 210.222 192.228 206.33 199.482 195.072C206.735 183.835 204.572 169.493 194.609 163.066C184.668 156.639 170.706 160.53 163.453 171.789Z'
        fill='url(#paint7_radial_128_10941)'
      />
      <path
        d='M231.84 107.297C246.238 98.9677 257.6 96.9346 267.323 101.81C258.255 99.2738 246.981 101.176 234.79 108.215C204.747 125.573 180.386 167.613 180.386 202.111C180.386 220.081 187.006 232.433 197.559 237.724C184.275 234.007 177.414 221.699 177.414 201.193C177.414 166.695 201.776 124.655 231.818 107.297H231.84Z'
        fill='url(#paint8_radial_128_10941)'
      />
      <path
        opacity='0.3'
        d='M295.74 201.636C319.174 161.526 319.406 118.035 296.259 104.496C273.111 90.956 235.35 112.495 211.916 152.605C188.482 192.714 188.25 236.205 211.397 249.745C234.545 263.284 272.306 241.745 295.74 201.636Z'
        fill='url(#paint9_radial_128_10941)'
      />
      <g opacity='0.2'>
        <path
          opacity='0.09'
          d='M215.147 543.612L233.544 533.337C234.287 532.9 268.808 514.055 269.464 514.449L281.328 508.634C286.681 505.551 294.547 504.524 301.342 504.524C307.263 504.524 313.577 506.382 317.816 508.83L400.798 556.117L418.496 566.195L519.176 623.582C522.432 625.462 525.447 628.348 526.998 631.212C528.244 633.464 528.986 635.912 529.052 638.47C529.249 645.138 525.163 651.434 517.8 655.697L499.403 665.972C498.66 666.409 464.139 685.254 463.483 684.861L451.619 690.676C446.266 693.758 438.4 694.786 431.605 694.786C425.684 694.786 419.37 692.928 415.131 690.479L332.149 643.192L314.451 633.114L213.771 575.727C210.515 573.847 207.5 570.961 205.949 568.097C204.703 565.846 203.961 563.397 203.895 560.839C203.698 554.172 207.784 547.875 215.147 543.612Z'
          fill='black'
        />
        <path
          opacity='0.18'
          d='M216.983 545.055L234.877 535.152C236.385 534.277 268.677 517.269 269.988 518.034L281.503 513.29C286.309 510.514 294.678 510.339 300.795 510.339C306.127 510.339 312.856 512.612 316.68 514.821L397.215 560.774L414.388 570.568L512.119 626.315C515.047 627.998 518.368 631.037 519.832 633.573C521.077 635.737 521.929 638.033 522.082 640.481C522.475 646.865 518.586 653.03 511.354 657.227L493.46 667.131C491.952 668.005 459.66 685.014 458.349 684.248L446.834 688.992C442.027 691.769 433.659 691.944 427.542 691.944C422.21 691.944 415.481 689.67 411.657 687.462L331.122 641.509L313.949 631.715L216.218 575.968C213.29 574.284 209.969 571.246 208.505 568.71C207.26 566.545 206.408 564.25 206.255 561.801C205.861 555.418 209.751 549.253 216.983 545.077V545.055Z'
          fill='black'
        />
        <path
          opacity='0.27'
          d='M218.818 546.52L236.232 536.988C238.482 535.698 268.59 520.505 270.535 521.641L281.699 517.947C285.982 515.476 294.853 516.132 300.293 516.132C305.012 516.132 312.201 518.821 315.587 520.789L393.697 565.387L410.346 574.896L505.106 629.026C507.706 630.534 511.376 633.682 512.709 635.912C513.976 638.011 514.938 640.132 515.156 642.471C515.724 648.592 512.054 654.604 504.953 658.714L487.539 668.246C485.289 669.536 455.181 684.729 453.236 683.593L442.071 687.287C437.789 689.758 428.918 689.102 423.478 689.102C418.758 689.102 411.57 686.413 408.183 684.445L330.073 639.847L313.424 630.338L218.665 576.208C216.065 574.7 212.394 571.552 211.062 569.322C209.794 567.223 208.833 565.102 208.614 562.763C208.046 556.642 211.717 550.63 218.818 546.52Z'
          fill='black'
        />
        <path
          opacity='0.36'
          d='M220.653 547.985L237.564 538.825C240.558 537.098 268.459 523.74 271.059 525.249L281.852 522.625C285.589 520.461 294.962 521.969 299.725 521.969C303.854 521.969 311.458 525.096 314.429 526.801L390.092 570.065L406.239 579.291L498.048 631.78C500.321 633.092 504.341 636.371 505.564 638.295C506.854 640.307 507.902 642.296 508.186 644.504C508.951 650.363 505.499 656.222 498.507 660.266L481.596 669.426C478.603 671.153 450.702 684.511 448.102 683.002L437.308 685.626C433.572 687.79 424.199 686.282 419.436 686.282C415.306 686.282 407.703 683.155 404.731 681.45L329.068 638.186L312.922 628.96L221.112 576.47C218.84 575.159 214.82 571.88 213.596 569.956C212.307 567.944 211.258 565.955 210.974 563.747C210.209 557.888 213.662 552.029 220.653 547.985Z'
          fill='black'
        />
        <path
          opacity='0.45'
          d='M222.489 549.449L238.897 540.661C242.633 538.497 268.328 526.976 271.583 528.856L282.027 527.282C285.239 525.423 295.093 527.784 299.179 527.784C302.718 527.784 310.737 531.326 313.293 532.813L386.531 574.721L402.153 583.663L490.991 634.513C492.936 635.65 497.306 639.038 498.398 640.656C499.709 642.58 500.845 644.416 501.216 646.515C502.178 652.09 498.922 657.818 492.084 661.775L475.675 670.563C471.939 672.727 446.244 684.248 442.989 682.368L432.545 683.942C429.333 685.801 419.479 683.44 415.394 683.44C411.854 683.44 403.835 679.898 401.279 678.411L328.041 636.503L312.419 627.561L223.581 576.711C221.637 575.574 217.267 572.185 216.174 570.568C214.863 568.644 213.727 566.808 213.356 564.709C212.394 559.134 215.65 553.406 222.51 549.449H222.489Z'
          fill='black'
        />
        <path
          opacity='0.55'
          d='M224.346 550.914L240.252 542.497C244.753 539.896 268.241 530.233 272.13 532.463L282.224 531.96C284.89 530.408 295.268 533.622 298.676 533.622C301.626 533.622 310.082 537.6 312.201 538.825L382.992 579.4L398.089 588.057L483.978 637.29C485.595 638.23 490.336 641.728 491.275 643.061C492.608 644.919 493.854 646.603 494.291 648.592C495.449 653.905 492.39 659.479 485.682 663.371L469.776 671.787C465.275 674.389 441.787 684.052 437.898 681.822L427.804 682.325C425.139 683.877 414.76 680.663 411.352 680.663C408.402 680.663 399.947 676.684 397.827 675.46L327.036 634.885L311.939 626.228L226.05 576.995C224.433 576.055 219.692 572.557 218.753 571.224C217.42 569.365 216.175 567.682 215.738 565.715C214.58 560.402 217.638 554.827 224.346 550.936V550.914Z'
          fill='black'
        />
        <path
          opacity='0.64'
          d='M226.181 552.357L241.607 544.312C246.851 541.273 268.131 533.447 272.676 536.07L282.421 536.638C284.562 535.392 295.421 539.459 298.152 539.459C300.512 539.459 309.382 543.853 311.087 544.837L379.452 584.078L394.025 592.451L476.964 640.044C478.253 640.787 483.366 644.416 484.153 645.444C485.507 647.237 486.84 648.745 487.364 650.625C488.741 655.675 485.879 661.097 479.28 664.901L463.855 672.946C458.611 675.985 437.33 683.811 432.786 681.188L423.041 680.619C420.9 681.866 410.041 677.799 407.31 677.799C404.95 677.799 396.079 673.405 394.375 672.421L326.01 633.18L311.436 624.807L228.497 577.214C227.208 576.47 222.096 572.841 221.309 571.814C219.954 570.021 218.622 568.513 218.097 566.633C216.721 561.583 219.583 556.161 226.181 552.357Z'
          fill='black'
        />
        <path
          opacity='0.73'
          d='M228.017 553.822L242.939 546.148C248.926 542.694 268.022 536.682 273.2 539.677L282.595 541.295C284.19 540.377 295.574 545.274 297.606 545.274C299.375 545.274 308.661 550.105 309.929 550.827L375.847 588.713L389.896 596.78L469.863 642.755C470.847 643.323 476.505 646.909 476.964 647.783C478.647 649.204 479.783 650.844 480.351 652.615C481.924 657.38 479.258 662.671 472.791 666.409L457.868 674.083C451.882 677.537 432.786 683.549 427.607 680.554L418.212 678.936C416.617 679.854 405.234 674.957 403.202 674.957C401.432 674.957 392.146 670.126 390.879 669.404L324.961 631.518L310.912 623.451L230.944 577.476C229.961 576.908 224.302 573.322 223.843 572.448C222.161 571.027 221.025 569.387 220.457 567.617C218.884 562.851 221.549 557.56 228.017 553.822Z'
          fill='black'
        />
        <path
          opacity='0.82'
          d='M229.852 555.286L244.272 547.985C251.023 544.093 267.891 539.918 273.724 543.284L282.77 545.973C283.841 545.361 295.705 551.089 297.059 551.089C298.239 551.089 307.962 556.336 308.792 556.817L372.264 593.37L385.788 601.152L462.806 645.488C463.461 645.859 469.492 649.576 469.798 650.144C471.545 651.456 472.747 652.986 473.381 654.626C475.151 659.129 472.703 664.267 466.345 667.918L451.925 675.22C445.174 679.111 428.306 683.287 422.473 679.92L413.427 677.231C412.356 677.843 400.492 672.115 399.138 672.115C397.958 672.115 388.235 666.868 387.405 666.387L323.933 629.835L310.409 622.052L233.391 577.717C232.736 577.345 226.705 573.628 226.4 573.06C224.652 571.748 223.45 570.218 222.816 568.578C221.047 564.075 223.494 558.937 229.852 555.286Z'
          fill='black'
        />
        <path
          opacity='0.91'
          d='M231.687 556.751L245.627 549.799C253.121 545.471 267.782 543.131 274.271 546.87L282.945 550.63C283.469 550.324 295.858 556.904 296.535 556.904C297.125 556.904 307.263 562.588 307.678 562.829L368.724 598.026L381.725 605.525L455.771 648.22C456.099 648.417 462.5 652.221 462.653 652.505C464.467 653.708 465.712 655.107 466.433 656.637C468.421 660.856 466.149 665.863 459.944 669.448L446.004 676.4C438.51 680.729 423.849 683.068 417.36 679.33L408.686 675.569C408.162 675.875 395.773 669.295 395.096 669.295C394.506 669.295 384.368 663.611 383.953 663.371L322.907 628.173L309.907 620.675L235.86 577.979C235.533 577.782 229.131 573.978 228.978 573.694C227.165 572.492 225.919 571.092 225.198 569.562C223.21 565.343 225.482 560.337 231.687 556.751Z'
          fill='black'
        />
        <path
          d='M412.247 678.696L321.88 626.49L231.512 574.284C224.368 570.152 225.263 562.96 233.5 558.194L246.938 551.614C255.175 546.848 267.65 546.345 274.773 550.455L365.141 602.661L455.508 654.866C462.653 658.998 461.757 666.191 453.52 670.957L440.083 677.537C431.846 682.303 419.37 682.806 412.247 678.696Z'
          fill='black'
        />
      </g>
      <path
        d='M421.555 665.753L259.435 572.098C241.147 561.539 226.334 533.184 226.334 508.809V76.822C226.334 64.4045 216.152 61.6718 222.314 57.212C222.554 57.0372 236.778 50.5661 237.062 50.4786C243.704 47.9208 250.827 46.8059 259.457 51.7904L421.577 145.446C439.864 156.005 454.678 184.36 454.678 208.735V640.722C454.678 653.686 450.483 662.933 443.819 667.153C443.426 667.393 426.274 675.569 425.837 675.722C419.938 677.69 429.552 670.388 421.577 665.775L421.555 665.753Z'
        fill='url(#paint10_linear_128_10941)'
      />
      <path
        d='M420.069 145.927L257.949 52.2713C249.319 47.2868 411.089 148.9 411.089 148.9C429.377 159.459 444.19 187.814 444.19 212.189C444.19 212.189 453.148 654.145 453.148 641.181V209.238C453.148 184.84 438.335 156.508 420.047 145.949L420.069 145.927Z'
        fill='url(#paint11_radial_128_10941)'
      />
      <path
        opacity='0.6'
        d='M408.97 671.503L246.85 577.848C228.563 567.289 213.749 538.934 213.749 514.558V82.5935C213.749 58.1959 228.563 46.9808 246.85 57.54L408.97 151.195C427.258 161.755 442.071 190.109 442.071 214.485V646.472C442.071 670.869 427.258 682.084 408.97 671.525V671.503Z'
        fill='url(#paint12_radial_128_10941)'
      />
      <path
        d='M406.086 673.077L243.966 579.422C225.678 568.863 210.865 540.508 210.865 516.132V84.1456C210.865 59.748 225.678 48.533 243.966 59.0921L406.086 152.748C424.373 163.307 439.187 191.661 439.187 216.037V648.024C439.187 672.421 424.373 683.636 406.086 673.077Z'
        fill='#32084F'
      />
      <path
        d='M215.366 518.996V86.4848C215.366 65.1041 228.366 55.2663 244.381 64.5357L405.627 157.688C421.664 166.958 434.642 191.792 434.642 213.173V645.684C434.642 667.065 421.642 676.903 405.627 667.634L244.381 574.481C228.344 565.212 215.366 540.377 215.366 518.996Z'
        fill='url(#paint13_linear_128_10941)'
      />
      <path
        d='M221.833 521.62V91.3163C221.833 73.4116 232.714 65.1916 246.129 72.9306L403.945 164.094C417.36 171.855 428.241 192.645 428.241 210.55V640.875C428.241 658.78 417.36 667 403.945 659.261L246.129 568.097C232.714 560.337 221.833 539.546 221.833 521.641V521.62Z'
        fill='url(#paint14_linear_128_10941)'
      />
      <path
        d='M247.943 77.6965C235.292 70.3947 225.045 76.2317 225.045 90.7697V525.926C225.045 540.442 235.292 558.129 247.943 565.452L402.109 654.517C414.76 661.818 425.007 655.981 425.007 641.443V206.265C425.007 191.749 414.76 174.063 402.109 166.739L247.943 77.6746V77.6965Z'
        fill='url(#paint15_linear_128_10941)'
      />
      <path
        d='M247.943 340.343C235.292 333.041 225.045 338.878 225.045 353.416V525.926C225.045 540.442 235.292 558.129 247.943 565.452L402.109 654.517C414.76 661.818 425.007 655.981 425.007 641.443V468.933C425.007 454.417 414.76 436.731 402.109 429.407L247.943 340.343Z'
        fill='url(#paint16_linear_128_10941)'
      />
      <path
        d='M402.109 429.407L247.943 340.343C235.292 333.041 225.045 338.878 225.045 353.416V356.63C225.045 342.113 235.292 336.254 247.943 343.556L402.109 432.621C414.76 439.923 425.007 457.609 425.007 472.147V468.933C425.007 454.417 414.76 436.731 402.109 429.407Z'
        fill='url(#paint17_radial_128_10941)'
      />
      <path
        d='M443.535 316.01V286.847C443.535 283.109 445.807 278.78 448.604 277.162C451.4 275.544 453.673 277.272 453.673 280.988V310.152C453.673 313.89 451.4 318.218 448.604 319.836C445.807 321.454 443.535 319.727 443.535 316.01Z'
        fill='url(#paint18_linear_128_10941)'
      />
      <path
        d='M446.594 319.334C445.152 319.334 444.627 317.541 444.627 315.989V286.825C444.627 283.502 446.703 279.502 449.15 278.102C449.675 277.796 450.177 277.643 450.614 277.643C452.056 277.643 452.58 279.436 452.58 280.988V310.152C452.58 313.475 450.505 317.453 448.058 318.874C447.533 319.18 447.031 319.334 446.594 319.334Z'
        fill='url(#paint19_linear_128_10941)'
      />
      <path
        d='M446.135 316.754V287.59C446.135 284.267 448.211 280.267 450.658 278.868C451.117 278.605 451.554 278.474 451.947 278.43C451.641 277.971 451.204 277.665 450.614 277.665C450.177 277.665 449.675 277.818 449.15 278.124C446.703 279.545 444.627 283.524 444.627 286.847V316.011C444.627 317.541 445.13 319.355 446.594 319.355C446.638 319.355 446.703 319.334 446.769 319.334C446.332 318.678 446.135 317.672 446.135 316.776V316.754Z'
        fill='url(#paint20_radial_128_10941)'
      />
      <path
        d='M212.482 183.507C212.088 183.266 211.761 183.463 211.761 183.922V185.846C211.761 186.305 212.088 186.874 212.482 187.092C212.875 187.333 213.203 187.136 213.203 186.677V184.753C213.203 184.294 212.875 183.725 212.482 183.507Z'
        fill='white'
      />
      <path
        d='M215.409 185.212C215.016 184.972 214.688 185.168 214.688 185.627V187.551C214.688 188.01 215.016 188.579 215.409 188.797C215.803 189.016 216.131 188.841 216.131 188.382V186.458C216.131 185.999 215.803 185.431 215.409 185.212Z'
        fill='white'
      />
      <path
        d='M218.359 182.851C217.966 182.61 217.638 182.807 217.638 183.266V193.301C217.638 193.76 217.966 194.328 218.359 194.547C218.752 194.766 219.08 194.591 219.08 194.132V184.097C219.08 183.638 218.752 183.07 218.359 182.851Z'
        fill='white'
      />
      <path
        d='M221.287 184.491C220.894 184.25 220.566 184.447 220.566 184.906V195.05C220.566 195.509 220.894 196.077 221.287 196.296C221.68 196.536 222.008 196.34 222.008 195.881V185.737C222.008 185.278 221.68 184.709 221.287 184.491Z'
        fill='white'
      />
      <path
        d='M224.237 181.933C223.843 181.692 223.516 181.889 223.516 182.348V200.996C223.516 201.455 223.843 202.024 224.237 202.242C224.63 202.461 224.958 202.286 224.958 201.827V183.179C224.958 182.72 224.63 182.151 224.237 181.933Z'
        fill='white'
      />
      <path
        d='M227.164 187.814C226.771 187.573 226.443 187.77 226.443 188.229V198.526C226.443 198.985 226.771 199.553 227.164 199.772C227.558 200.012 227.885 199.816 227.885 199.357V189.06C227.885 188.601 227.558 188.032 227.164 187.814Z'
        fill='white'
      />
      <path
        d='M230.114 182.807C229.721 182.567 229.393 182.764 229.393 183.223V206.921C229.393 207.38 229.721 207.948 230.114 208.167C230.507 208.407 230.835 208.211 230.835 207.751V184.053C230.835 183.594 230.507 183.026 230.114 182.807Z'
        fill='white'
      />
      <path
        d='M233.042 180.228C232.649 179.987 232.321 180.184 232.321 180.643V212.889C232.321 213.348 232.649 213.916 233.042 214.135C233.435 214.375 233.763 214.179 233.763 213.72V181.474C233.763 181.015 233.435 180.446 233.042 180.228Z'
        fill='white'
      />
      <path
        d='M235.992 177.517C235.598 177.276 235.271 177.473 235.271 177.932V218.988C235.271 219.448 235.598 220.016 235.992 220.235C236.385 220.475 236.713 217.808 236.713 217.349V178.763C236.713 178.304 236.385 177.735 235.992 177.517Z'
        fill='white'
      />
      <path
        d='M238.919 182.37C238.526 182.13 238.198 182.326 238.198 182.785V217.545C238.198 218.005 238.526 218.573 238.919 218.792C239.313 219.032 239.64 218.835 239.64 218.376V183.616C239.64 183.157 239.313 182.589 238.919 182.37Z'
        fill='white'
      />
      <path
        d='M241.868 182.61C241.475 182.37 241.147 182.567 241.147 183.026V220.672C241.147 221.131 241.475 221.699 241.868 221.918C242.262 222.136 242.589 221.962 242.589 221.502V183.857C242.589 183.398 242.262 182.829 241.868 182.61Z'
        fill='white'
      />
      <path
        d='M244.796 180.883C244.403 180.643 244.075 180.84 244.075 181.299V225.831C244.075 226.29 244.403 226.859 244.796 227.077C245.189 227.296 245.517 227.121 245.517 226.662V182.13C245.517 181.67 245.189 181.102 244.796 180.883Z'
        fill='white'
      />
      <path
        d='M247.746 177.079C247.353 176.839 247.025 177.036 247.025 177.495V233.002C247.025 233.461 247.353 234.029 247.746 234.248C248.139 234.488 248.467 234.291 248.467 233.832V178.326C248.467 177.867 248.139 177.298 247.746 177.079Z'
        fill='white'
      />
      <path
        d='M250.674 183.594C250.28 183.354 249.953 183.551 249.953 184.01V229.875C249.953 230.335 250.28 230.903 250.674 231.122C251.067 231.362 251.395 231.165 251.395 230.706V184.84C251.395 184.381 251.067 183.813 250.674 183.594Z'
        fill='white'
      />
      <path
        d='M253.623 188.032C253.23 187.792 252.902 187.988 252.902 188.447V228.848C252.902 229.307 253.23 229.875 253.623 230.094C254.017 230.313 254.344 230.138 254.344 229.679V189.278C254.344 188.819 254.017 188.251 253.623 188.032Z'
        fill='white'
      />
      <path
        d='M256.551 185.671C256.158 185.431 255.83 185.627 255.83 186.087V234.619C255.83 235.079 256.158 235.647 256.551 235.866C256.944 236.106 257.272 235.909 257.272 235.45V186.917C257.272 186.458 256.944 185.89 256.551 185.671Z'
        fill='white'
      />
      <path
        d='M259.501 191.574C259.108 191.333 258.78 191.53 258.78 191.989V232.127C258.78 232.586 259.108 233.155 259.501 233.373C259.894 233.614 260.222 233.417 260.222 232.958V192.82C260.222 192.361 259.894 191.792 259.501 191.574Z'
        fill='white'
      />
      <path
        d='M262.429 195.487C262.035 195.246 261.708 195.443 261.708 195.902V231.581C261.708 232.04 262.035 232.608 262.429 232.827C262.822 233.067 263.15 232.87 263.15 232.411V196.733C263.15 196.274 262.822 195.706 262.429 195.487Z'
        fill='white'
      />
      <path
        d='M265.378 198.985C264.985 198.744 264.657 198.941 264.657 199.4V231.471C264.657 231.93 264.985 232.499 265.378 232.717C265.772 232.936 266.099 232.761 266.099 232.302V200.231C266.099 199.772 265.772 199.204 265.378 198.985Z'
        fill='white'
      />
      <path
        d='M268.306 201.936C267.913 201.696 267.585 201.892 267.585 202.352V231.93C267.585 232.389 267.913 232.958 268.306 233.177C268.699 233.395 269.027 233.22 269.027 232.761V203.182C269.027 202.723 268.699 202.155 268.306 201.936Z'
        fill='white'
      />
      <path
        d='M271.256 202.002C270.862 201.761 270.535 201.958 270.535 202.417V235.231C270.535 235.691 270.862 236.259 271.256 236.478C271.649 236.696 271.977 236.521 271.977 236.062V203.248C271.977 202.789 271.649 202.22 271.256 202.002Z'
        fill='white'
      />
      <path
        d='M274.183 206.746C273.79 206.505 273.462 206.702 273.462 207.161V233.92C273.462 234.379 273.79 234.947 274.183 235.166C274.577 235.385 274.904 235.21 274.904 234.751V207.992C274.904 207.533 274.577 206.964 274.183 206.746Z'
        fill='white'
      />
      <path
        d='M277.133 211.271C276.74 211.031 276.412 211.227 276.412 211.687V232.783C276.412 233.242 276.74 233.811 277.133 234.029C277.526 234.27 277.854 234.073 277.854 233.614V212.517C277.854 212.058 277.526 211.49 277.133 211.271Z'
        fill='white'
      />
      <path
        d='M280.061 210.725C279.668 210.484 279.34 210.681 279.34 211.14V236.74C279.34 237.199 279.668 237.768 280.061 237.986C280.454 238.227 280.782 238.03 280.782 237.571V211.971C280.782 211.512 280.454 210.943 280.061 210.725Z'
        fill='white'
      />
      <path
        d='M283.011 220.256C282.617 220.016 282.29 220.213 282.29 220.672V230.597C282.29 231.056 282.617 231.624 283.011 231.843C283.404 232.062 283.732 231.887 283.732 231.428V221.502C283.732 221.043 283.404 220.475 283.011 220.256Z'
        fill='white'
      />
      <path
        d='M285.938 224.301C285.545 224.06 285.217 224.257 285.217 224.716V229.919C285.217 230.378 285.545 230.947 285.938 231.165C286.332 231.406 286.659 231.209 286.659 230.75V225.547C286.659 225.088 286.332 224.519 285.938 224.301Z'
        fill='white'
      />
      <path
        d='M288.888 214.682C288.495 214.441 288.167 214.638 288.167 215.097V242.971C288.167 243.43 288.495 243.998 288.888 244.217C289.281 244.435 289.609 244.26 289.609 243.801V215.928C289.609 215.469 289.281 214.9 288.888 214.682Z'
        fill='white'
      />
      <path
        d='M291.816 218.485C291.422 218.245 291.095 218.442 291.095 218.901V242.511C291.095 242.971 291.422 243.539 291.816 243.758C292.209 243.976 292.537 243.801 292.537 243.342V219.732C292.537 219.273 292.209 218.704 291.816 218.485Z'
        fill='white'
      />
      <path
        d='M294.765 211.249C294.372 211.009 294.044 211.206 294.044 211.665V253.158C294.044 253.617 294.372 254.186 294.765 254.404C295.158 254.645 295.486 254.448 295.486 253.989V212.495C295.486 212.036 295.158 211.468 294.765 211.249Z'
        fill='white'
      />
      <path
        d='M297.693 211.293C297.299 211.053 296.972 211.249 296.972 211.708V256.503C296.972 256.962 297.299 257.53 297.693 257.749C298.086 257.968 298.414 257.793 298.414 257.334V212.539C298.414 212.08 298.086 211.512 297.693 211.293Z'
        fill='white'
      />
      <path
        d='M300.642 208.451C300.249 208.21 299.921 208.407 299.921 208.866V262.777C299.921 263.236 300.249 263.805 300.642 264.023C301.036 264.264 301.363 264.067 301.363 263.608V209.697C301.363 209.238 301.036 208.67 300.642 208.451Z'
        fill='white'
      />
      <path
        d='M303.57 206.615C303.177 206.374 302.849 206.571 302.849 207.03V267.958C302.849 268.418 303.177 268.986 303.57 269.205C303.963 269.445 304.291 269.248 304.291 268.789V207.861C304.291 207.402 303.963 206.833 303.57 206.615Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M306.52 210.309C306.127 210.069 305.799 210.266 305.799 210.725V267.674C305.799 268.133 306.127 268.702 306.52 268.92C306.913 269.161 307.241 268.964 307.241 268.505V211.555C307.241 211.096 306.913 210.528 306.52 210.309Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M309.448 214.376C309.054 214.135 308.727 214.332 308.727 214.791V266.997C308.727 267.456 309.054 268.024 309.448 268.243C309.841 268.483 310.169 268.286 310.169 267.827V215.622C310.169 215.163 309.841 214.594 309.448 214.376Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M312.397 214.069C312.004 213.829 311.676 214.026 311.676 214.485V270.735C311.676 271.194 312.004 271.762 312.397 271.981C312.791 272.222 313.118 272.025 313.118 271.566V215.316C313.118 214.856 312.791 214.288 312.397 214.069Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M315.325 211.927C314.932 211.687 314.604 211.883 314.604 212.342V276.244C314.604 276.703 314.932 277.272 315.325 277.49C315.718 277.731 316.046 277.534 316.046 277.075V213.173C316.046 212.714 315.718 212.146 315.325 211.927Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M318.275 222.836C317.881 222.595 317.554 222.792 317.554 223.251V268.767C317.554 269.226 317.881 269.795 318.275 270.013C318.668 270.254 318.996 270.057 318.996 269.598V224.082C318.996 223.623 318.668 223.055 318.275 222.836Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M321.202 224.629C320.809 224.388 320.481 224.585 320.481 225.044V270.363C320.481 270.822 320.809 271.391 321.202 271.609C321.596 271.85 321.923 271.653 321.923 271.194V225.875C321.923 225.416 321.596 224.847 321.202 224.629Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M324.152 229.482C323.759 229.241 323.431 229.438 323.431 229.897V268.877C323.431 269.336 323.759 269.904 324.152 270.123C324.545 270.363 324.873 270.167 324.873 269.707V230.728C324.873 230.269 324.545 229.701 324.152 229.482Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M327.08 236.456C326.687 236.215 326.359 236.412 326.359 236.871V267.74C326.359 268.199 326.687 268.767 327.08 268.986C327.473 269.226 327.801 269.03 327.801 268.571V237.702C327.801 237.243 327.473 236.674 327.08 236.456Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M330.03 236.478C329.636 236.237 329.309 236.434 329.309 236.893V268.658C329.309 269.117 329.636 269.686 330.03 269.904C330.423 270.123 330.751 269.948 330.751 269.489V237.724C330.751 237.265 330.423 236.696 330.03 236.478Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M332.957 244.413C332.564 244.173 332.236 244.37 332.236 244.829V264.111C332.236 264.57 332.564 265.138 332.957 265.357C333.351 265.597 333.678 265.401 333.678 264.942V245.66C333.678 245.2 333.351 244.632 332.957 244.413Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M335.907 238.074C335.514 237.833 335.186 238.03 335.186 238.489V273.883C335.186 274.342 335.514 274.91 335.907 275.129C336.3 275.348 336.628 275.173 336.628 274.714V239.32C336.628 238.861 336.3 238.292 335.907 238.074Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M338.835 238.62C338.442 238.38 338.114 238.576 338.114 239.036V276.703C338.114 277.162 338.442 277.731 338.835 277.949C339.228 278.19 339.556 277.993 339.556 277.534V239.866C339.556 239.407 339.228 238.839 338.835 238.62Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M341.784 237.308C341.391 237.068 341.063 237.265 341.063 237.724V281.447C341.063 281.906 341.391 282.475 341.784 282.693C342.178 282.934 342.505 282.737 342.505 282.278V238.554C342.505 238.095 342.178 237.527 341.784 237.308Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M344.712 241.899C344.319 241.659 343.991 241.856 343.991 242.315V280.223C343.991 280.682 344.319 281.25 344.712 281.469C345.106 281.688 345.433 281.513 345.433 281.054V243.145C345.433 242.686 345.106 242.118 344.712 241.899Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M347.661 244.982C347.268 244.741 346.94 244.938 346.94 245.397V280.529C346.94 280.988 347.268 281.556 347.661 281.775C348.055 281.994 348.382 281.819 348.382 281.36V246.228C348.382 245.769 348.055 245.2 347.661 244.982Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M350.589 251.147C350.196 250.906 349.868 251.103 349.868 251.562V277.774C349.868 278.233 350.196 278.802 350.589 279.021C350.982 279.261 351.31 279.064 351.31 278.605V252.393C351.31 251.934 350.982 251.365 350.589 251.147Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M353.539 252.393C353.146 252.152 352.818 252.349 352.818 252.808V279.917C352.818 280.376 353.146 280.944 353.539 281.163C353.932 281.403 354.26 281.207 354.26 280.748V253.639C354.26 253.18 353.932 252.612 353.539 252.393Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M356.467 262.799C356.073 262.559 355.746 262.755 355.746 263.214V272.899C355.746 273.358 356.073 273.927 356.467 274.145C356.86 274.386 357.188 274.189 357.188 273.73V264.045C357.188 263.586 356.86 263.018 356.467 262.799Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M359.416 259.848C359.023 259.607 358.695 259.804 358.695 260.263V279.261C358.695 279.72 359.023 280.288 359.416 280.507C359.81 280.748 360.137 280.551 360.137 280.092V261.094C360.137 260.635 359.81 260.066 359.416 259.848Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M362.344 259.17C361.951 258.93 361.623 259.126 361.623 259.585V283.349C361.623 283.808 361.951 284.377 362.344 284.595C362.737 284.836 363.065 284.639 363.065 284.18V260.416C363.065 259.957 362.737 259.389 362.344 259.17Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M365.272 257.968C364.878 257.727 364.551 257.924 364.551 258.383V287.918C364.551 288.377 364.878 288.946 365.272 289.164C365.665 289.383 365.993 289.208 365.993 288.749V259.214C365.993 258.755 365.665 258.186 365.272 257.968Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M368.222 263.848C367.828 263.608 367.5 263.805 367.5 264.264V285.448C367.5 285.907 367.828 286.475 368.222 286.694C368.615 286.912 368.943 286.738 368.943 286.278V265.095C368.943 264.635 368.615 264.067 368.222 263.848Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M371.171 261.837C370.778 261.597 370.45 261.793 370.45 262.253V290.848C370.45 291.307 370.778 291.875 371.171 292.094C371.564 292.334 371.892 292.137 371.892 291.678V263.083C371.892 262.624 371.564 262.056 371.171 261.837Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M374.099 264.176C373.706 263.936 373.378 264.133 373.378 264.592V291.897C373.378 292.356 373.706 292.925 374.099 293.143C374.492 293.362 374.82 293.187 374.82 292.728V265.423C374.82 264.963 374.492 264.395 374.099 264.176Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M377.049 261.378C376.655 261.138 376.328 261.334 376.328 261.793V298.128C376.328 298.587 376.655 299.155 377.049 299.374C377.442 299.592 377.77 299.417 377.77 298.958V262.624C377.77 262.165 377.442 261.597 377.049 261.378Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M379.976 263.892C379.583 263.652 379.255 263.849 379.255 264.308V298.98C379.255 299.439 379.583 300.008 379.976 300.226C380.37 300.467 380.697 300.27 380.697 299.811V265.138C380.697 264.679 380.37 264.111 379.976 263.892Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M382.904 262.034C382.511 261.793 382.183 261.99 382.183 262.449V304.249C382.183 304.708 382.511 305.276 382.904 305.495C383.297 305.735 383.625 305.539 383.625 305.08V263.28C383.625 262.821 383.297 262.253 382.904 262.034Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M385.854 258.099C385.461 257.858 385.133 258.055 385.133 258.514V311.572C385.133 312.032 385.461 312.6 385.854 312.819C386.247 313.037 386.575 312.862 386.575 312.403V259.345C386.575 258.886 386.247 258.317 385.854 258.099Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M388.803 264.308C388.41 264.067 388.082 264.264 388.082 264.723V308.752C388.082 309.211 388.41 309.78 388.803 309.998C389.196 310.217 389.524 310.042 389.524 309.583V265.554C389.524 265.095 389.196 264.526 388.803 264.308Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M391.731 263.324C391.338 263.083 391.01 263.28 391.01 263.739V313.147C391.01 313.606 391.338 314.174 391.731 314.393C392.125 314.633 392.452 314.436 392.452 313.977V264.57C392.452 264.111 392.125 263.542 391.731 263.324Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M394.659 267.412C394.266 267.171 393.938 267.368 393.938 267.827V312.469C393.938 312.928 394.266 313.496 394.659 313.715C395.052 313.955 395.38 313.759 395.38 313.3V268.658C395.38 268.199 395.052 267.631 394.659 267.412Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M397.608 274.408C397.215 274.167 396.887 274.364 396.887 274.823V308.84C396.887 309.299 397.215 309.867 397.608 310.086C398.002 310.326 398.329 310.13 398.329 309.67V275.654C398.329 275.195 398.002 274.626 397.608 274.408Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M400.536 273.14C400.143 272.899 399.815 273.096 399.815 273.555V313.496C399.815 313.955 400.143 314.524 400.536 314.742C400.93 314.961 401.257 314.786 401.257 314.327V274.386C401.257 273.927 400.93 273.358 400.536 273.14Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M403.486 278.299C403.092 278.058 402.765 278.255 402.765 278.714V311.747C402.765 312.206 403.092 312.775 403.486 312.993C403.879 313.212 404.207 313.037 404.207 312.578V279.545C404.207 279.086 403.879 278.518 403.486 278.299Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M406.413 282.584C406.02 282.343 405.692 282.54 405.692 282.999V310.851C405.692 311.31 406.02 311.879 406.413 312.097C406.807 312.316 407.134 312.141 407.134 311.682V283.83C407.134 283.371 406.807 282.803 406.413 282.584Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M409.363 285.819C408.97 285.579 408.642 285.776 408.642 286.235V311.004C408.642 311.463 408.97 312.032 409.363 312.25C409.756 312.491 410.084 312.294 410.084 311.835V287.066C410.084 286.606 409.756 286.038 409.363 285.819Z'
        fill='white'
      />
      <g opacity='0.8'>
        <path
          d='M286.746 321.367C294.219 325.695 300.315 322.219 300.315 313.628C300.315 305.036 294.241 294.542 286.746 290.214C279.274 285.885 273.178 289.361 273.178 297.953C273.178 306.544 279.252 317.038 286.746 321.367Z'
          fill='#9F19AB'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M257.622 384.918L248.554 387.017C247.746 387.214 246.61 385.88 246.61 384.743V370.096C246.61 368.959 247.746 368.937 248.554 370.052L257.622 382.623C258.321 383.585 258.321 384.765 257.622 384.94V384.918ZM251.154 364.587C243.748 360.302 237.717 363.756 237.717 372.26C237.717 380.765 243.748 391.171 251.154 395.456C258.561 399.741 264.592 396.308 264.592 387.782C264.592 379.256 258.561 368.872 251.154 364.587Z'
          fill='#7C1DC9'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M257.622 437.102L248.554 439.201C247.746 439.398 246.61 438.064 246.61 436.927V422.28C246.61 421.143 247.746 421.121 248.554 422.236L257.622 434.807C258.321 435.769 258.321 436.949 257.622 437.124V437.102ZM251.154 416.771C243.748 412.486 237.717 415.94 237.717 424.444C237.717 432.948 243.748 443.355 251.154 447.64C258.561 451.924 264.592 448.492 264.592 439.966C264.592 431.44 258.561 421.056 251.154 416.771Z'
          fill='#7C1DC9'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M257.622 489.308L248.554 491.407C247.746 491.603 246.61 490.27 246.61 489.133V474.486C246.61 473.349 247.746 473.327 248.554 474.442L257.622 487.012C258.321 487.974 258.321 489.155 257.622 489.33V489.308ZM251.154 468.977C243.748 464.692 237.717 468.146 237.717 476.65C237.717 485.154 243.748 495.56 251.154 499.845C258.561 504.13 264.592 500.698 264.592 492.172C264.592 483.646 258.561 473.261 251.154 468.977Z'
          fill='#7C1DC9'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M257.622 541.492L248.554 543.59C247.746 543.787 246.61 542.454 246.61 541.317V526.669C246.61 525.533 247.746 525.511 248.554 526.626L257.622 539.196C258.321 540.158 258.321 541.339 257.622 541.513V541.492ZM251.154 521.16C243.748 516.875 237.717 520.33 237.717 528.834C237.717 537.338 243.748 547.744 251.154 552.029C258.561 556.314 264.592 552.882 264.592 544.356C264.592 535.829 258.561 525.445 251.154 521.16Z'
          fill='#7C1DC9'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M271.999 382.142L276.303 384.634C277.745 385.465 278.837 386.558 279.58 387.87C280.301 389.181 280.673 390.712 280.673 392.439V394.538C280.673 396.265 280.301 397.38 279.58 397.839C278.859 398.32 277.767 398.123 276.303 397.292L274.926 396.483V405.184L271.999 403.501V382.12V382.142ZM276.303 394.253C276.783 394.538 277.133 394.581 277.373 394.428C277.614 394.253 277.723 393.816 277.723 393.073V390.537C277.723 389.794 277.614 389.225 277.373 388.766C277.133 388.329 276.783 387.957 276.303 387.695L274.926 386.886V393.444L276.303 394.253Z'
          fill='#7C1DC9'
        />
        <path
          d='M283.207 408.879C282.442 407.501 282.071 405.949 282.071 404.244V393.007C282.071 391.302 282.442 390.187 283.207 389.706C283.972 389.203 285.064 389.378 286.506 390.209C287.948 391.04 289.041 392.133 289.805 393.51C290.57 394.887 290.941 396.418 290.941 398.145V409.382C290.941 411.087 290.57 412.202 289.805 412.683C289.041 413.186 287.948 413.011 286.506 412.18C285.064 411.349 283.972 410.256 283.207 408.879ZM288.014 407.895V396.243C288.014 394.844 287.511 393.838 286.506 393.27C285.501 392.679 284.999 393.095 284.999 394.494V406.146C284.999 407.545 285.501 408.551 286.506 409.119C287.511 409.71 288.014 409.294 288.014 407.895Z'
          fill='#7C1DC9'
        />
        <path
          d='M292.908 394.21L297.365 396.789C298.829 397.62 299.9 398.713 300.643 400.025C301.364 401.337 301.735 402.867 301.735 404.594V415.394C301.735 417.121 301.364 418.236 300.643 418.695C299.922 419.176 298.829 418.979 297.365 418.148L292.908 415.568V394.188V394.21ZM297.322 415.066C297.802 415.35 298.174 415.394 298.414 415.219C298.676 415.044 298.807 414.585 298.807 413.863V402.758C298.807 402.036 298.676 401.424 298.414 400.943C298.152 400.462 297.781 400.09 297.322 399.806L295.836 398.954V414.213L297.322 415.066Z'
          fill='#7C1DC9'
        />
        <path
          d='M304.619 421.274C303.876 419.941 303.527 418.432 303.527 416.771V405.294C303.527 403.632 303.898 402.539 304.619 402.058C305.362 401.577 306.433 401.73 307.831 402.539C309.229 403.348 310.3 404.419 311.043 405.774C311.786 407.108 312.157 408.616 312.157 410.3V412.552L309.382 410.956V408.485C309.382 407.152 308.902 406.212 307.918 405.665C306.935 405.097 306.455 405.49 306.455 406.802V418.738C306.455 420.05 306.935 420.968 307.918 421.537C308.902 422.105 309.382 421.733 309.382 420.422V417.164L312.157 418.76V421.777C312.157 423.439 311.786 424.532 311.043 425.013C310.3 425.494 309.229 425.341 307.831 424.532C306.433 423.723 305.362 422.652 304.619 421.296V421.274Z'
          fill='#7C1DC9'
        />
        <path
          d='M316.133 407.633L320.088 409.928L323.125 433.058L320.197 431.374L319.673 426.827V426.893L316.352 424.969L315.827 428.839L313.118 427.264L316.155 407.655L316.133 407.633ZM319.323 423.767L318.012 412.442H317.969L316.701 422.236L319.345 423.767H319.323Z'
          fill='#7C1DC9'
        />
        <path
          d='M325.048 433.08C324.327 431.724 323.956 430.194 323.956 428.467V427.243L326.73 428.839V430.303C326.73 431.681 327.233 432.664 328.238 433.255C328.74 433.539 329.112 433.583 329.374 433.408C329.636 433.233 329.767 432.752 329.767 432.008C329.767 431.112 329.593 430.216 329.243 429.341C328.893 428.445 328.238 427.265 327.277 425.756C326.075 423.832 325.223 422.258 324.742 420.99C324.261 419.722 324.021 418.476 324.021 417.23C324.021 415.547 324.393 414.454 325.135 413.951C325.878 413.448 326.971 413.623 328.369 414.432C329.767 415.241 330.838 416.312 331.559 417.667C332.28 419.001 332.63 420.553 332.63 422.28V423.176L329.855 421.58V420.487C329.855 419.766 329.724 419.154 329.483 418.673C329.243 418.192 328.872 417.82 328.391 417.536C327.408 416.968 326.927 417.383 326.927 418.738C326.927 419.504 327.102 420.334 327.473 421.231C327.845 422.105 328.5 423.308 329.461 424.816C330.685 426.74 331.537 428.336 331.996 429.604C332.455 430.872 332.695 432.162 332.695 433.517C332.695 435.266 332.324 436.403 331.559 436.884C330.816 437.386 329.702 437.212 328.282 436.381C326.861 435.55 325.791 434.479 325.07 433.123L325.048 433.08Z'
          fill='#7C1DC9'
        />
        <path
          d='M336.65 422.542L333.591 420.772V417.711L342.636 422.936V425.996L339.577 424.226V442.546L336.65 440.862V422.542Z'
          fill='#7C1DC9'
        />
        <path
          d='M366.19 456.865C365.447 455.532 365.097 454.001 365.097 452.296V450.547L367.872 452.143V454.045C367.872 455.444 368.375 456.45 369.38 457.018C369.882 457.302 370.254 457.346 370.516 457.128C370.778 456.931 370.909 456.362 370.909 455.466V453.717C370.909 452.733 370.756 451.946 370.45 451.356C370.144 450.766 369.664 450.285 368.986 449.891L368.003 449.323V446.262L369.074 446.874C369.664 447.224 370.101 447.29 370.385 447.115C370.669 446.94 370.822 446.437 370.822 445.628V444.076C370.822 443.355 370.69 442.721 370.428 442.24C370.166 441.737 369.817 441.365 369.358 441.103C368.418 440.556 367.959 440.95 367.959 442.283V443.683L365.185 442.087V440.95C365.185 439.245 365.556 438.13 366.277 437.649C367.02 437.168 368.069 437.321 369.467 438.108C370.865 438.917 371.936 439.988 372.657 441.3C373.378 442.633 373.749 444.142 373.749 445.847V446.612C373.749 447.771 373.575 448.601 373.247 449.104C372.919 449.607 372.395 449.804 371.674 449.716V449.782C372.438 450.547 372.985 451.4 373.312 452.318C373.64 453.236 373.815 454.286 373.815 455.422V457.324C373.815 459.029 373.443 460.123 372.722 460.604C371.98 461.084 370.909 460.91 369.467 460.079C368.025 459.248 366.954 458.155 366.211 456.821L366.19 456.865Z'
          fill='#7C1DC9'
        />
        <path
          d='M375.934 448.689L378.753 450.307V453.542L375.934 451.924V448.689ZM375.934 460.319L378.753 461.959V465.195L375.934 463.555V460.319Z'
          fill='#7C1DC9'
        />
        <path
          d='M385.024 452.515L381.965 450.744V448.361C382.795 448.842 383.472 449.148 383.975 449.279C384.477 449.41 384.871 449.367 385.177 449.17C385.483 448.973 385.745 448.58 385.985 447.989L387.951 449.126V470.507L385.024 468.824V452.515Z'
          fill='#7C1DC9'
        />
        <path
          d='M391.513 470.244C391.513 468.714 391.731 467.534 392.146 466.703C392.583 465.85 393.326 465.085 394.44 464.407C395.511 463.708 396.276 463.008 396.691 462.309C397.106 461.609 397.324 460.756 397.324 459.729C397.324 458.811 397.193 458.111 396.931 457.608C396.669 457.106 396.298 456.712 395.795 456.428C394.79 455.838 394.287 456.253 394.287 457.652V459.948L391.513 458.352V456.275C391.513 454.548 391.884 453.433 392.627 452.93C393.37 452.427 394.44 452.602 395.861 453.411C397.281 454.242 398.351 455.313 399.094 456.668C399.837 458.024 400.208 459.576 400.208 461.303C400.208 462.615 399.946 463.73 399.4 464.648C398.854 465.566 397.936 466.462 396.603 467.293C395.729 467.84 395.14 468.364 394.855 468.867C394.55 469.348 394.397 469.982 394.397 470.725V471.185L399.946 474.398V477.459L391.491 472.584V470.266L391.513 470.244Z'
          fill='#7C1DC9'
        />
        <path
          d='M271.999 434.479L276.303 436.971C277.745 437.802 278.837 438.895 279.58 440.207C280.301 441.518 280.673 443.049 280.673 444.776V446.874C280.673 448.601 280.301 449.716 279.58 450.175C278.859 450.656 277.767 450.46 276.303 449.629L274.926 448.82V457.521L271.999 455.838V434.457V434.479ZM276.303 446.59C276.783 446.874 277.133 446.918 277.373 446.743C277.614 446.568 277.723 446.131 277.723 445.388V442.852C277.723 442.109 277.614 441.54 277.373 441.081C277.133 440.644 276.783 440.272 276.303 440.01L274.926 439.201V445.759L276.303 446.568V446.59Z'
          fill='#7C1DC9'
        />
        <path
          d='M283.207 461.216C282.442 459.838 282.071 458.286 282.071 456.581V445.344C282.071 443.639 282.442 442.524 283.207 442.043C283.972 441.54 285.064 441.715 286.506 442.546C287.948 443.377 289.041 444.47 289.805 445.847C290.57 447.224 290.941 448.776 290.941 450.482V461.719C290.941 463.424 290.57 464.539 289.805 465.02C289.041 465.522 287.948 465.348 286.506 464.517C285.064 463.686 283.972 462.593 283.207 461.216ZM288.014 460.232V448.58C288.014 447.181 287.511 446.175 286.506 445.606C285.501 445.016 284.999 445.432 284.999 446.831V458.505C284.999 459.904 285.501 460.91 286.506 461.478C287.511 462.068 288.014 461.653 288.014 460.254V460.232Z'
          fill='#7C1DC9'
        />
        <path
          d='M292.908 446.547L297.365 449.126C298.829 449.957 299.9 451.05 300.643 452.362C301.364 453.673 301.735 455.204 301.735 456.931V467.73C301.735 469.458 301.364 470.573 300.643 471.032C299.922 471.513 298.829 471.316 297.365 470.485L292.908 467.905V446.525V446.547ZM297.322 467.424C297.802 467.709 298.174 467.752 298.414 467.577C298.676 467.403 298.807 466.943 298.807 466.222V455.116C298.807 454.395 298.676 453.783 298.414 453.302C298.152 452.821 297.781 452.449 297.322 452.187L295.836 451.334V466.594L297.322 467.446V467.424Z'
          fill='#7C1DC9'
        />
        <path
          d='M304.619 473.611C303.876 472.278 303.527 470.769 303.527 469.086V457.608C303.527 455.947 303.898 454.854 304.619 454.373C305.362 453.892 306.433 454.045 307.831 454.854C309.229 455.663 310.3 456.734 311.043 458.068C311.786 459.401 312.157 460.91 312.157 462.593V464.845L309.382 463.249V460.778C309.382 459.467 308.902 458.505 307.918 457.958C306.935 457.39 306.455 457.761 306.455 459.095V471.032C306.455 472.343 306.935 473.261 307.918 473.83C308.902 474.398 309.382 474.027 309.382 472.715V469.458L312.157 471.053V474.07C312.157 475.732 311.786 476.825 311.043 477.306C310.3 477.787 309.229 477.634 307.831 476.825C306.433 476.016 305.362 474.945 304.619 473.589V473.611Z'
          fill='#7C1DC9'
        />
        <path
          d='M316.133 459.969L320.088 462.265L323.125 485.395L320.197 483.711L319.673 479.164V479.23L316.352 477.306L315.827 481.175L313.118 479.601L316.155 459.991L316.133 459.969ZM319.323 476.103L318.012 464.779H317.969L316.701 474.573L319.345 476.103H319.323Z'
          fill='#7C1DC9'
        />
        <path
          d='M325.048 485.395C324.327 484.039 323.956 482.509 323.956 480.782V479.558L326.73 481.153V482.618C326.73 483.996 327.233 484.979 328.238 485.57C328.74 485.854 329.112 485.897 329.374 485.723C329.636 485.548 329.767 485.067 329.767 484.302C329.767 483.405 329.593 482.509 329.243 481.634C328.893 480.738 328.238 479.558 327.277 478.049C326.075 476.125 325.223 474.551 324.742 473.283C324.261 472.015 324.021 470.769 324.021 469.523C324.021 467.84 324.393 466.747 325.135 466.244C325.878 465.741 326.971 465.916 328.369 466.725C329.767 467.534 330.838 468.605 331.559 469.96C332.28 471.294 332.63 472.846 332.63 474.573V475.469L329.855 473.874V472.78C329.855 472.059 329.724 471.447 329.483 470.966C329.243 470.485 328.872 470.113 328.391 469.829C327.408 469.261 326.927 469.676 326.927 471.032C326.927 471.797 327.102 472.627 327.473 473.502C327.845 474.376 328.5 475.579 329.461 477.087C330.685 479.011 331.537 480.607 331.996 481.875C332.455 483.143 332.695 484.433 332.695 485.788C332.695 487.537 332.324 488.674 331.559 489.155C330.816 489.658 329.702 489.483 328.282 488.652C326.861 487.821 325.791 486.75 325.07 485.395H325.048Z'
          fill='#7C1DC9'
        />
        <path
          d='M336.65 474.857L333.591 473.086V470.026L342.636 475.251V478.311L339.577 476.541V494.861L336.65 493.177V474.857Z'
          fill='#7C1DC9'
        />
        <path
          d='M366.321 509.224C365.556 507.847 365.185 506.294 365.185 504.589V493.527C365.185 489.833 366.67 488.827 369.642 490.554C371.062 491.363 372.133 492.456 372.875 493.811C373.618 495.167 373.99 496.719 373.99 498.446V498.84L371.237 497.244V496.632C371.237 495.232 370.734 494.227 369.729 493.658C369.183 493.33 368.768 493.309 368.506 493.527C368.243 493.768 368.112 494.358 368.112 495.342V499.43H368.156C368.68 498.512 369.554 498.402 370.822 499.146C371.892 499.758 372.701 500.632 373.247 501.791C373.793 502.95 374.077 504.305 374.077 505.901V509.727C374.077 511.432 373.706 512.547 372.941 513.028C372.176 513.531 371.084 513.356 369.642 512.525C368.2 511.694 367.107 510.601 366.343 509.224H366.321ZM371.128 508.284V504.502C371.128 503.103 370.625 502.097 369.62 501.529C368.615 500.938 368.112 501.354 368.112 502.753V506.535C368.112 507.912 368.615 508.896 369.62 509.486C370.625 510.077 371.128 509.661 371.128 508.284Z'
          fill='#7C1DC9'
        />
        <path
          d='M375.934 501.004L378.753 502.644V505.879L375.934 504.239V501.004ZM375.934 512.634L378.753 514.252V517.488L375.934 515.87V512.634Z'
          fill='#7C1DC9'
        />
        <path
          d='M381.877 518.209C381.113 516.832 380.741 515.301 380.741 513.574V502.338C380.741 500.632 381.113 499.517 381.877 499.036C382.642 498.534 383.735 498.708 385.177 499.539C386.619 500.37 387.711 501.463 388.476 502.84C389.24 504.218 389.612 505.748 389.612 507.475V518.712C389.612 520.417 389.24 521.532 388.476 522.013C387.711 522.516 386.619 522.341 385.177 521.51C383.735 520.679 382.642 519.586 381.877 518.209ZM386.684 517.225V505.573C386.684 504.174 386.182 503.168 385.177 502.6C384.172 502.01 383.669 502.425 383.669 503.824V515.476C383.669 516.876 384.172 517.881 385.177 518.45C386.182 519.04 386.684 518.624 386.684 517.225Z'
          fill='#7C1DC9'
        />
        <path
          d='M392.605 524.418C391.862 523.062 391.491 521.51 391.491 519.783V518.1L394.244 519.696V521.619C394.244 522.997 394.746 523.98 395.751 524.571C396.756 525.161 397.259 524.746 397.259 523.368V518.581C397.259 517.181 396.756 516.176 395.751 515.607C394.746 515.017 394.244 515.433 394.244 516.832V517.007L391.491 515.411L392.015 503.78L399.728 508.24V511.301L394.615 508.349L394.375 513.312H394.419C394.965 512.612 395.817 512.591 396.953 513.246C398.024 513.859 398.832 514.755 399.378 515.892C399.924 517.05 400.208 518.406 400.208 520.002V524.833C400.208 526.56 399.837 527.675 399.094 528.178C398.351 528.681 397.281 528.506 395.861 527.697C394.44 526.866 393.37 525.795 392.627 524.44L392.605 524.418Z'
          fill='#7C1DC9'
        />
        <path
          d='M271.999 486.794L276.303 489.286C277.745 490.117 278.837 491.21 279.58 492.522C280.301 493.833 280.673 495.364 280.673 497.091V499.189C280.673 500.916 280.301 502.031 279.58 502.491C278.859 502.971 277.767 502.775 276.303 501.944L274.926 501.135V509.836L271.999 508.153V486.772V486.794ZM276.303 498.905C276.783 499.189 277.133 499.233 277.373 499.08C277.614 498.905 277.723 498.468 277.723 497.725V495.189C277.723 494.445 277.614 493.877 277.373 493.418C277.133 492.981 276.783 492.609 276.303 492.347L274.926 491.538V498.096L276.303 498.905Z'
          fill='#7C1DC9'
        />
        <path
          d='M283.207 513.531C282.442 512.153 282.071 510.601 282.071 508.896V497.659C282.071 495.954 282.442 494.839 283.207 494.358C283.972 493.855 285.064 494.03 286.506 494.861C287.948 495.692 289.041 496.785 289.805 498.162C290.57 499.539 290.941 501.069 290.941 502.797V514.033C290.941 515.739 290.57 516.854 289.805 517.335C289.041 517.837 287.948 517.662 286.506 516.832C285.064 516.001 283.972 514.908 283.207 513.531ZM288.014 512.547V500.895C288.014 499.495 287.511 498.49 286.506 497.921C285.501 497.331 284.999 497.747 284.999 499.146V510.798C284.999 512.197 285.501 513.203 286.506 513.771C287.511 514.361 288.014 513.946 288.014 512.547Z'
          fill='#7C1DC9'
        />
        <path
          d='M292.908 498.861L297.365 501.441C298.829 502.272 299.9 503.365 300.643 504.677C301.364 505.988 301.735 507.519 301.735 509.246V520.045C301.735 521.773 301.364 522.887 300.643 523.347C299.922 523.828 298.829 523.631 297.365 522.8L292.908 520.22V498.84V498.861ZM297.322 519.718C297.802 520.002 298.174 520.045 298.414 519.871C298.676 519.696 298.807 519.237 298.807 518.515V507.409C298.807 506.688 298.676 506.076 298.414 505.595C298.152 505.114 297.781 504.742 297.322 504.458L295.836 503.605V518.865L297.322 519.718Z'
          fill='#7C1DC9'
        />
        <path
          d='M304.619 525.926C303.876 524.593 303.527 523.084 303.527 521.423V509.945C303.527 508.284 303.898 507.191 304.619 506.71C305.362 506.229 306.433 506.382 307.831 507.191C309.229 508 310.3 509.071 311.043 510.404C311.786 511.738 312.157 513.246 312.157 514.93V517.181L309.382 515.586V513.115C309.382 511.782 308.902 510.842 307.918 510.295C306.935 509.727 306.455 510.12 306.455 511.432V523.368C306.455 524.68 306.935 525.598 307.918 526.167C308.902 526.735 309.382 526.363 309.382 525.052V521.794L312.157 523.39V526.407C312.157 528.069 311.786 529.162 311.043 529.643C310.3 530.124 309.229 529.971 307.831 529.162C306.433 528.353 305.362 527.282 304.619 525.926Z'
          fill='#7C1DC9'
        />
        <path
          d='M316.133 512.285L320.088 514.58L323.125 537.71L320.197 536.026L319.673 531.479V531.545L316.352 529.621L315.827 533.49L313.118 531.916L316.155 512.306L316.133 512.285ZM319.323 528.418L318.012 517.094H317.969L316.701 526.888L319.345 528.418H319.323Z'
          fill='#7C1DC9'
        />
        <path
          d='M325.048 537.731C324.327 536.376 323.956 534.846 323.956 533.119V531.894L326.73 533.49V534.955C326.73 536.332 327.233 537.316 328.238 537.906C328.74 538.191 329.112 538.234 329.374 538.059C329.636 537.884 329.767 537.404 329.767 536.66C329.767 535.764 329.593 534.868 329.243 533.993C328.893 533.097 328.238 531.916 327.277 530.408C326.075 528.484 325.223 526.91 324.742 525.642C324.261 524.374 324.021 523.128 324.021 521.882C324.021 520.198 324.393 519.105 325.135 518.603C325.878 518.1 326.971 518.275 328.369 519.083C329.767 519.892 330.838 520.964 331.559 522.319C332.28 523.653 332.63 525.205 332.63 526.932V527.806L329.855 526.21V525.117C329.855 524.396 329.724 523.784 329.483 523.303C329.243 522.822 328.872 522.45 328.391 522.166C327.408 521.598 326.927 521.991 326.927 523.368C326.927 524.133 327.102 524.964 327.473 525.861C327.845 526.735 328.5 527.937 329.461 529.446C330.685 531.37 331.537 532.966 331.996 534.234C332.455 535.502 332.695 536.791 332.695 538.147C332.695 539.896 332.324 541.033 331.559 541.514C330.816 542.016 329.702 541.841 328.282 541.011C326.861 540.18 325.791 539.109 325.07 537.753L325.048 537.731Z'
          fill='#7C1DC9'
        />
        <path
          d='M336.65 527.194L333.591 525.423V522.363L342.636 527.588V530.648L339.577 528.878V547.198L336.65 545.514V527.194Z'
          fill='#7C1DC9'
        />
        <path
          d='M366.19 561.495C365.447 560.162 365.097 558.631 365.097 556.926V555.177L367.872 556.773V558.675C367.872 560.074 368.375 561.08 369.38 561.648C369.882 561.932 370.254 561.976 370.516 561.758C370.778 561.561 370.909 560.992 370.909 560.096V558.347C370.909 557.363 370.756 556.576 370.45 555.986C370.144 555.396 369.664 554.893 368.986 554.521L368.003 553.953V550.892L369.074 551.504C369.664 551.832 370.101 551.92 370.385 551.745C370.669 551.57 370.822 551.067 370.822 550.258V548.706C370.822 547.963 370.69 547.351 370.428 546.87C370.166 546.367 369.817 545.995 369.358 545.733C368.418 545.186 367.959 545.58 367.959 546.913V548.313L365.185 546.717V545.58C365.185 543.875 365.556 542.76 366.277 542.279C367.02 541.798 368.069 541.951 369.467 542.738C370.865 543.547 371.936 544.618 372.657 545.93C373.378 547.241 373.749 548.772 373.749 550.477V551.242C373.749 552.401 373.575 553.231 373.247 553.734C372.919 554.237 372.395 554.434 371.674 554.346V554.412C372.438 555.177 372.985 556.03 373.312 556.948C373.64 557.866 373.815 558.915 373.815 560.052V561.954C373.815 563.659 373.443 564.753 372.722 565.234C371.98 565.714 370.909 565.54 369.467 564.709C368.025 563.878 366.954 562.785 366.211 561.451L366.19 561.495Z'
          fill='#7C1DC9'
        />
        <path
          d='M375.934 553.341L378.753 554.958V558.194L375.934 556.576V553.341ZM375.934 564.971L378.753 566.611V569.846L375.934 568.229V564.993V564.971Z'
          fill='#7C1DC9'
        />
        <path
          d='M380.894 568.731C380.894 567.201 381.112 566.021 381.528 565.168C381.943 564.337 382.707 563.55 383.822 562.872C384.892 562.173 385.657 561.473 386.072 560.774C386.487 560.074 386.706 559.222 386.706 558.194C386.706 557.276 386.575 556.576 386.312 556.073C386.05 555.571 385.679 555.177 385.176 554.893C384.171 554.303 383.669 554.718 383.669 556.117V558.413L380.894 556.817V554.74C380.894 553.013 381.265 551.898 382.008 551.395C382.751 550.892 383.822 551.067 385.242 551.876C386.662 552.685 387.733 553.778 388.476 555.133C389.218 556.489 389.59 558.041 389.59 559.768C389.59 561.08 389.328 562.195 388.781 563.113C388.235 564.031 387.318 564.927 385.985 565.758C385.111 566.305 384.521 566.829 384.237 567.332C383.931 567.813 383.778 568.447 383.778 569.19V569.65L389.328 572.863V575.924L380.872 571.049V568.731H380.894Z'
          fill='#7C1DC9'
        />
        <path
          d='M392.496 576.667C391.731 575.29 391.36 573.738 391.36 572.032V560.796C391.36 559.09 391.731 557.975 392.496 557.494C393.261 556.992 394.353 557.167 395.795 557.997C397.237 558.828 398.33 559.921 399.094 561.298C399.859 562.676 400.231 564.228 400.231 565.933V577.17C400.231 578.875 399.859 579.99 399.094 580.471C398.33 580.974 397.237 580.799 395.795 579.968C394.353 579.137 393.261 578.044 392.496 576.667ZM397.303 575.683V564.031C397.303 562.632 396.8 561.626 395.795 561.058C394.79 560.468 394.288 560.883 394.288 562.282V573.956C394.288 575.355 394.79 576.361 395.795 576.929C396.8 577.52 397.303 577.104 397.303 575.705V575.683Z'
          fill='#7C1DC9'
        />
        <path
          d='M271.999 539.109L276.303 541.601C277.745 542.432 278.837 543.525 279.58 544.837C280.301 546.148 280.673 547.679 280.673 549.406V551.504C280.673 553.231 280.301 554.346 279.58 554.805C278.859 555.286 277.767 555.09 276.303 554.259L274.926 553.45V562.151L271.999 560.468V539.087V539.109ZM276.303 551.22C276.783 551.504 277.133 551.548 277.373 551.395C277.614 551.22 277.723 550.783 277.723 550.04V547.504C277.723 546.782 277.614 546.192 277.373 545.733C277.133 545.296 276.783 544.924 276.303 544.662L274.926 543.853V550.411L276.303 551.22Z'
          fill='#7C1DC9'
        />
        <path
          d='M283.207 565.846C282.442 564.468 282.071 562.916 282.071 561.211V549.974C282.071 548.269 282.442 547.154 283.207 546.673C283.972 546.17 285.064 546.345 286.506 547.176C287.948 548.006 289.041 549.1 289.805 550.477C290.57 551.854 290.941 553.406 290.941 555.111V566.348C290.941 568.054 290.57 569.169 289.805 569.65C289.041 570.152 287.948 569.977 286.506 569.147C285.064 568.316 283.972 567.223 283.207 565.846ZM288.014 564.862V553.188C288.014 551.789 287.511 550.783 286.506 550.214C285.501 549.624 284.999 550.04 284.999 551.439V563.091C284.999 564.49 285.501 565.496 286.506 566.064C287.511 566.654 288.014 566.239 288.014 564.84V564.862Z'
          fill='#7C1DC9'
        />
        <path
          d='M292.908 551.176L297.365 553.756C298.829 554.587 299.9 555.68 300.643 556.992C301.364 558.303 301.735 559.834 301.735 561.561V572.36C301.735 574.087 301.364 575.202 300.643 575.661C299.922 576.142 298.829 575.946 297.365 575.115L292.908 572.535V551.155V551.176ZM297.322 572.054C297.802 572.339 298.174 572.382 298.414 572.207C298.676 572.032 298.807 571.573 298.807 570.852V559.746C298.807 559.003 298.676 558.413 298.414 557.932C298.152 557.451 297.781 557.079 297.322 556.817L295.836 555.964V571.224L297.322 572.076V572.054Z'
          fill='#7C1DC9'
        />
        <path
          d='M304.619 578.263C303.876 576.93 303.527 575.421 303.527 573.738V562.26C303.527 560.599 303.898 559.506 304.619 559.025C305.362 558.544 306.433 558.697 307.831 559.506C309.229 560.315 310.3 561.386 311.043 562.741C311.786 564.075 312.157 565.583 312.157 567.267V569.518L309.382 567.922V565.452C309.382 564.14 308.902 563.178 307.918 562.632C306.935 562.064 306.455 562.457 306.455 563.769V575.705C306.455 577.017 306.935 577.935 307.918 578.504C308.902 579.072 309.382 578.7 309.382 577.389V574.131L312.157 575.727V578.744C312.157 580.406 311.786 581.499 311.043 581.98C310.3 582.46 309.229 582.307 307.831 581.499C306.433 580.69 305.362 579.618 304.619 578.285V578.263Z'
          fill='#7C1DC9'
        />
        <path
          d='M316.133 564.621L320.088 566.917L323.125 590.046L320.197 588.363L319.673 583.816V583.881L316.352 581.958L315.827 585.827L313.118 584.253L316.155 564.643L316.133 564.621ZM319.323 580.755L318.012 569.431H317.969L316.701 579.225L319.345 580.755H319.323Z'
          fill='#7C1DC9'
        />
        <path
          d='M325.048 590.047C324.327 588.691 323.956 587.161 323.956 585.434V584.209L326.73 585.805V587.27C326.73 588.647 327.233 589.631 328.238 590.221C328.74 590.506 329.112 590.549 329.374 590.374C329.636 590.178 329.767 589.719 329.767 588.953C329.767 588.057 329.593 587.161 329.243 586.286C328.893 585.39 328.238 584.209 327.277 582.701C326.075 580.777 325.223 579.181 324.742 577.935C324.261 576.667 324.021 575.421 324.021 574.175C324.021 572.492 324.393 571.399 325.135 570.896C325.878 570.393 326.971 570.568 328.369 571.377C329.767 572.186 330.838 573.257 331.559 574.59C332.28 575.924 332.63 577.476 332.63 579.203V580.099L329.855 578.504V577.41C329.855 576.667 329.724 576.077 329.483 575.596C329.243 575.115 328.872 574.743 328.391 574.459C327.408 573.891 326.927 574.306 326.927 575.662C326.927 576.427 327.102 577.257 327.473 578.132C327.845 579.006 328.5 580.209 329.461 581.717C330.685 583.641 331.537 585.237 331.996 586.505C332.455 587.773 332.695 589.063 332.695 590.418C332.695 592.167 332.324 593.304 331.559 593.785C330.816 594.288 329.702 594.113 328.282 593.282C326.861 592.451 325.791 591.38 325.07 590.025L325.048 590.047Z'
          fill='#7C1DC9'
        />
        <path
          d='M336.65 579.509L333.591 577.738V574.678L342.636 579.903V582.963L339.577 581.193V599.513L336.65 597.829V579.509Z'
          fill='#7C1DC9'
        />
        <path
          d='M366.343 613.898C365.6 612.542 365.229 610.99 365.229 609.263V607.58L368.003 609.175V611.099C368.003 612.477 368.506 613.46 369.511 614.051C370.516 614.641 371.018 614.225 371.018 612.848V608.061C371.018 606.661 370.516 605.656 369.511 605.087C368.506 604.497 368.003 604.912 368.003 606.312V606.486L365.229 604.891L365.753 593.26L373.466 597.72V600.781L368.353 597.829L368.113 602.792H368.156C368.703 602.092 369.555 602.07 370.691 602.726C371.761 603.338 372.57 604.213 373.116 605.372C373.662 606.53 373.946 607.886 373.946 609.482V614.313C373.946 616.04 373.575 617.155 372.832 617.658C372.089 618.161 370.997 617.986 369.598 617.177C368.178 616.346 367.108 615.275 366.343 613.919V613.898Z'
          fill='#7C1DC9'
        />
        <path
          d='M375.934 605.656L378.753 607.295V610.531L375.934 608.891V605.656ZM375.934 617.286L378.753 618.904V622.139L375.934 620.5V617.264V617.286Z'
          fill='#7C1DC9'
        />
        <path
          d='M381.877 622.861C381.113 621.484 380.741 619.931 380.741 618.226V606.989C380.741 605.284 381.113 604.169 381.877 603.688C382.642 603.185 383.735 603.36 385.177 604.191C386.619 605.022 387.711 606.115 388.476 607.492C389.24 608.869 389.612 610.4 389.612 612.127V623.364C389.612 625.069 389.24 626.184 388.476 626.665C387.711 627.168 386.619 626.993 385.177 626.162C383.735 625.331 382.642 624.238 381.877 622.861ZM386.684 621.877V610.225C386.684 608.826 386.182 607.82 385.177 607.252C384.172 606.661 383.669 607.077 383.669 608.476V620.128C383.669 621.527 384.172 622.533 385.177 623.101C386.182 623.692 386.684 623.276 386.684 621.877Z'
          fill='#7C1DC9'
        />
        <path
          d='M392.605 629.07C391.862 627.714 391.491 626.162 391.491 624.435V622.751L394.244 624.347V626.271C394.244 627.649 394.746 628.632 395.751 629.223C396.756 629.813 397.259 629.397 397.259 628.02V623.232C397.259 621.833 396.756 620.828 395.751 620.259C394.746 619.669 394.244 620.084 394.244 621.484V621.658L391.491 620.063L392.015 608.432L399.728 612.892V615.953L394.615 613.001L394.375 617.964H394.419C394.965 617.264 395.817 617.242 396.953 617.898C398.024 618.51 398.832 619.407 399.378 620.544C399.924 621.702 400.208 623.058 400.208 624.654V629.485C400.208 631.212 399.837 632.327 399.094 632.83C398.351 633.333 397.281 633.158 395.861 632.349C394.44 631.518 393.37 630.447 392.627 629.091L392.605 629.07Z'
          fill='#7C1DC9'
        />
      </g>
      <g>
        <path
          d='M417.251 503.387V500.632L232.801 394.078V396.833L417.251 503.387Z'
          fill='#B086CC'
        />
      </g>
      <g>
        <path
          d='M232.801 451.225L417.251 557.757V555.002L232.801 448.448V451.225Z'
          fill='#B086CC'
        />
      </g>
      <g>
        <path
          d='M417.251 612.149V609.394L232.801 502.84V505.595L417.251 612.149Z'
          fill='#B086CC'
        />
      </g>
      <path
        opacity='0.3'
        d='M469.603 307.302C493.037 267.193 493.269 223.702 470.122 210.162C446.974 196.623 409.212 218.162 385.778 258.271C362.345 298.381 362.112 341.872 385.26 355.411C408.407 368.951 446.169 347.412 469.603 307.302Z'
        fill='url(#paint21_radial_128_10941)'
      />
      <path
        d='M380.239 312.119C380.239 328.974 386.051 340.911 395.511 346.682C395.795 346.857 402.066 350.399 402.35 350.552C412.182 356.061 405.89 343.578 420.834 334.943C450.877 317.585 473.447 280.529 473.447 246.031C473.447 228.979 489.178 224.148 483.322 219.622C483.082 219.426 472.922 214.179 472.66 214.026C462.85 208.713 449.456 209.697 434.687 218.223C404.644 235.581 380.282 277.621 380.282 312.119H380.239Z'
        fill='url(#paint22_linear_128_10941)'
      />
      <path
        d='M481.101 307.344C502.482 270.748 502.697 231.067 481.581 218.716C460.465 206.365 426.014 226.019 404.632 262.616C383.251 299.213 383.035 338.893 404.152 351.244C425.268 363.596 459.719 343.941 481.101 307.344Z'
        fill='url(#paint23_linear_128_10941)'
      />
      <path
        d='M409.872 307.111C417.135 295.865 414.955 281.532 405.002 275.097C395.049 268.662 381.093 272.562 373.83 283.808C366.567 295.055 368.748 309.388 378.7 315.823C388.653 322.258 402.609 318.358 409.872 307.111Z'
        fill='url(#paint24_radial_128_10941)'
      />
      <path
        d='M441.984 222.136C456.382 213.807 467.744 211.774 477.467 216.649C468.399 214.113 457.125 216.015 444.934 223.055C414.891 240.413 390.53 282.453 390.53 316.95C390.53 334.921 397.15 347.273 407.703 352.563C394.419 348.847 387.558 336.538 387.558 316.032C387.558 281.535 411.92 239.495 441.962 222.136H441.984Z'
        fill='url(#paint25_radial_128_10941)'
      />
      <path
        d='M401.279 315.508C401.279 330.221 406.348 340.627 414.607 345.677C414.848 345.83 431.103 354.531 431.344 354.662C439.93 359.471 434.446 348.562 447.468 341.042C473.687 325.892 494.946 289.208 494.946 259.105C494.946 244.217 509.476 245.047 501.064 240.063C500.823 239.932 482.142 230.028 481.902 229.897C473.337 225.263 461.648 226.115 448.757 233.57C422.538 248.72 401.279 285.404 401.279 315.508Z'
        fill='url(#paint26_linear_128_10941)'
      />
      <path
        d='M500.065 316.954C518.724 285.017 518.911 250.388 500.483 239.609C482.054 228.83 451.989 245.982 433.329 277.919C414.67 309.857 414.483 344.486 432.911 355.265C451.339 366.044 481.405 348.892 500.065 316.954Z'
        fill='url(#paint27_linear_128_10941)'
      />
      <path
        d='M465.93 242.599C478.494 235.341 488.413 233.548 496.89 237.811C488.981 235.603 479.149 237.243 468.509 243.408C442.29 258.558 421.031 295.242 421.031 325.345C421.031 341.02 426.799 351.798 436.019 356.433C424.417 353.197 418.431 342.441 418.431 324.558C418.431 294.455 439.69 257.771 465.909 242.621L465.93 242.599Z'
        fill='url(#paint28_radial_128_10941)'
      />
      <path
        d='M441.044 320.273C441.044 330.155 444.453 337.129 449.981 340.517C450.155 340.627 453.258 342.682 453.433 342.791C459.201 346.005 455.508 338.703 464.248 333.653C481.858 323.487 496.126 298.871 496.126 278.649C496.126 268.658 505.368 268.571 499.709 265.248C499.556 265.16 495.339 262.908 495.164 262.821C489.418 259.717 481.574 260.285 472.922 265.291C455.312 275.457 441.044 300.073 441.044 320.295V320.273Z'
        fill='url(#paint29_linear_128_10941)'
      />
      <path
        d='M499.54 317.473C512.061 296.043 512.186 272.806 499.821 265.573C487.455 258.341 467.281 269.85 454.76 291.281C442.239 312.711 442.114 335.948 454.479 343.181C466.845 350.414 487.019 338.904 499.54 317.473Z'
        fill='#B086CC'
      />
      <path
        d='M476.658 267.565C485.092 262.69 491.734 261.487 497.437 264.351C492.128 262.865 485.529 263.98 478.385 268.112C460.774 278.277 446.507 302.893 446.507 323.116C446.507 333.631 450.374 340.867 456.579 343.972C448.801 341.785 444.781 334.593 444.781 322.569C444.781 302.369 459.048 277.731 476.658 267.565Z'
        fill='url(#paint30_radial_128_10941)'
      />
      <path
        opacity='0.8'
        d='M495.748 315.193C504.578 300.079 504.668 283.692 495.949 278.592C487.23 273.492 473.003 281.61 464.172 296.724C455.342 311.838 455.252 328.225 463.971 333.325C472.691 338.425 486.918 330.307 495.748 315.193Z'
        fill='url(#paint31_linear_128_10941)'
      />
      <path
        d='M479.586 280.004C485.529 276.572 490.227 275.719 494.247 277.731C490.489 276.681 485.857 277.468 480.81 280.376C468.399 287.546 458.327 304.905 458.327 319.158C458.327 326.57 461.058 331.685 465.428 333.871C459.944 332.341 457.104 327.247 457.104 318.787C457.104 304.533 467.176 287.175 479.586 280.004Z'
        fill='url(#paint32_radial_128_10941)'
      />
      <path
        d='M208.876 26.6714H208.942C209.095 26.5839 209.27 26.4746 209.423 26.3652L208.876 26.6714Z'
        fill='url(#paint33_radial_128_10941)'
      />
      <path
        d='M354.216 28.2672C310.627 3.08251 271.714 -6.97386 246.129 5.07192V5.00633L214.426 23.3264C239.99 10.6904 279.252 20.6811 323.3 46.1281C400.318 90.6166 462.762 165.493 462.762 253.945V281.906L462.893 281.994L493.678 264.242V236.062C493.678 147.632 431.234 72.7556 354.216 28.2453V28.2672Z'
        fill='url(#paint34_linear_128_10941)'
      />
      <path
        d='M323.3 46.1282C246.26 1.63965 183.837 4.37237 183.837 92.8028V109.374L187.967 111.757V92.8028C187.967 45.8002 205.533 21.9929 240.186 21.9929C261.838 21.9929 289.871 31.5683 321.268 49.7135C407.309 99.4269 456.666 175.702 456.666 253.88V279.676L462.784 282.147V253.967C462.784 165.537 400.339 90.6604 323.321 46.15L323.3 46.1282Z'
        fill='url(#paint35_linear_128_10941)'
      />
      <g>
        <path
          d='M186.416 93.5461V110.773L188.163 111.779V92.8247C188.163 57.1246 198.323 34.8038 218.381 26.1466C197.143 34.2573 186.416 56.8404 186.416 93.568V93.5461Z'
          fill='url(#paint36_radial_128_10941)'
        />
        <path
          d='M323.3 46.1282C280.388 21.337 242.021 11.2151 216.436 22.3864C241.999 12.3956 279.623 22.6269 321.552 46.8496C398.569 91.3381 461.014 166.214 461.014 254.667V281.469L462.762 282.125V253.945C462.762 165.515 400.317 90.6385 323.3 46.1282Z'
          fill='url(#paint37_radial_128_10941)'
        />
      </g>
      <g opacity='0.3'>
        <path
          opacity='0.05'
          d='M233.763 284.071C218.381 275.173 205.905 282.3 205.905 299.964V520.701C205.905 538.366 218.381 559.899 233.763 568.797L267.257 586.439C282.639 595.337 301.145 587.27 301.145 569.584V348.847C301.145 331.182 282.661 310.611 267.257 301.713L233.763 284.071Z'
          fill='url(#paint38_linear_128_10941)'
        />
        <path
          opacity='0.1'
          d='M233.894 286.738C218.883 278.081 206.735 285.011 206.735 302.238V518.253C206.735 535.48 218.905 556.467 233.894 565.146L266.558 582.351C281.568 591.008 299.441 583.182 299.441 565.955V349.94C299.441 332.713 281.568 312.622 266.558 303.965L233.894 286.76V286.738Z'
          fill='url(#paint39_linear_128_10941)'
        />
        <path
          opacity='0.14'
          d='M234.003 289.405C219.386 280.966 207.544 287.721 207.544 304.511V515.804C207.544 532.594 219.386 553.035 234.003 561.473L265.837 578.241C280.454 586.68 297.715 579.072 297.715 562.282V350.989C297.715 334.199 280.454 314.611 265.837 306.173L234.003 289.405Z'
          fill='url(#paint40_linear_128_10941)'
        />
        <path
          opacity='0.19'
          d='M234.112 292.072C219.888 283.852 208.352 290.432 208.352 306.763V513.334C208.352 529.665 219.888 549.581 234.112 557.801L265.116 574.153C279.339 582.373 296.01 574.984 296.01 558.631V352.06C296.01 335.73 279.361 316.623 265.116 308.403L234.112 292.05V292.072Z'
          fill='url(#paint41_linear_128_10941)'
        />
        <path
          opacity='0.24'
          d='M234.221 294.739C220.369 286.738 209.139 293.143 209.139 309.037V510.885C209.139 526.779 220.369 546.148 234.221 554.15L264.395 570.065C278.247 578.066 294.284 570.896 294.284 555.002V353.153C294.284 337.26 278.247 318.634 264.395 310.654L234.221 294.739Z'
          fill='url(#paint42_linear_128_10941)'
        />
        <path
          opacity='0.29'
          d='M234.331 297.406C220.872 289.623 209.947 295.854 209.947 311.31V508.415C209.947 523.871 220.85 542.716 234.331 550.477L263.696 565.955C277.155 573.738 292.58 566.786 292.58 551.329V354.225C292.58 338.768 277.155 320.645 263.696 312.862L234.331 297.384V297.406Z'
          fill='url(#paint43_linear_128_10941)'
        />
        <path
          opacity='0.33'
          d='M234.44 300.073C221.374 292.509 210.755 298.565 210.755 313.584V505.967C210.755 520.986 221.352 539.284 234.44 546.826L262.975 561.867C276.062 569.431 290.876 562.698 290.876 547.7V355.318C290.876 340.299 276.062 322.678 262.975 315.114L234.44 300.073Z'
          fill='url(#paint44_linear_128_10941)'
        />
        <path
          opacity='0.38'
          d='M234.549 302.74C221.855 295.417 211.564 301.276 211.564 315.857V503.518C211.564 518.1 221.855 535.851 234.549 543.175L262.254 557.779C274.948 565.102 289.15 558.631 289.15 544.049V356.389C289.15 341.807 274.948 324.69 262.254 317.344L234.549 302.74Z'
          fill='url(#paint45_linear_128_10941)'
        />
        <path
          opacity='0.43'
          d='M234.659 305.408C222.358 298.303 212.373 303.987 212.373 318.109V501.048C212.373 515.17 222.358 532.397 234.659 539.502L261.533 553.691C273.834 560.796 287.424 554.543 287.424 540.399V357.46C287.424 343.338 273.834 326.679 261.533 319.574L234.659 305.386V305.408Z'
          fill='url(#paint46_linear_128_10941)'
        />
        <path
          opacity='0.48'
          d='M234.767 308.075C222.838 301.188 213.181 306.697 213.181 320.383V498.599C213.181 512.285 222.838 528.965 234.767 535.851L260.812 549.602C272.741 556.489 285.698 550.455 285.698 536.77V358.553C285.698 344.868 272.719 328.712 260.812 321.826L234.767 308.075Z'
          fill='url(#paint47_linear_128_10941)'
        />
        <path
          opacity='0.52'
          d='M234.877 310.764C223.341 304.096 213.989 309.43 213.989 322.678V496.172C213.989 509.421 223.341 525.555 234.877 532.222L260.091 545.536C271.627 552.204 283.993 546.389 283.993 533.141V359.668C283.993 346.42 271.627 330.767 260.091 324.099L234.877 310.786V310.764Z'
          fill='url(#paint48_linear_128_10941)'
        />
        <path
          opacity='0.57'
          d='M234.986 313.431C223.843 306.982 214.798 312.141 214.798 324.952V493.702C214.798 506.513 223.843 522.1 234.986 528.55L259.37 541.426C270.513 547.875 282.268 542.279 282.268 529.49V360.739C282.268 347.928 270.513 332.756 259.37 326.329L234.986 313.453V313.431Z'
          fill='url(#paint49_linear_128_10941)'
        />
        <path
          opacity='0.62'
          d='M235.095 316.098C224.324 309.889 215.606 314.852 215.606 327.225V491.254C215.606 503.605 224.324 518.668 235.095 524.899L258.649 537.338C269.42 543.547 280.541 538.191 280.541 525.839V361.811C280.541 349.459 269.398 334.768 258.649 328.559L235.095 316.12V316.098Z'
          fill='url(#paint50_linear_128_10941)'
        />
        <path
          opacity='0.67'
          d='M235.227 318.765C224.848 312.775 216.437 317.563 216.437 329.477V488.783C216.437 500.698 224.848 515.214 235.227 521.226L257.95 533.228C268.328 539.218 278.859 534.081 278.859 522.166V362.86C278.859 350.945 268.328 336.757 257.95 330.767L235.227 318.765Z'
          fill='url(#paint51_linear_128_10941)'
        />
        <path
          opacity='0.71'
          d='M235.336 321.432C225.351 315.661 217.245 320.274 217.245 331.751V486.335C217.245 497.812 225.351 511.804 235.336 517.575L257.228 529.162C267.213 534.933 277.133 530.036 277.133 518.559V363.975C277.133 352.498 267.235 338.812 257.228 333.019L235.336 321.432Z'
          fill='url(#paint52_linear_128_10941)'
        />
        <path
          opacity='0.76'
          d='M235.445 324.099C225.831 318.546 218.053 322.984 218.053 334.024V483.886C218.053 494.926 225.853 508.371 235.445 513.924L256.507 525.074C266.121 530.626 275.407 525.948 275.407 514.908V365.046C275.407 354.006 266.121 340.802 256.507 335.249L235.445 324.099Z'
          fill='url(#paint53_linear_128_10941)'
        />
        <path
          opacity='0.81'
          d='M235.554 326.766C226.334 321.432 218.861 325.695 218.861 336.298V481.416C218.861 491.997 226.334 504.917 235.554 510.251L255.808 520.964C265.028 526.298 273.724 521.838 273.724 511.235V366.117C273.724 355.536 265.05 342.813 255.808 337.479L235.554 326.766Z'
          fill='url(#paint54_linear_128_10941)'
        />
        <path
          opacity='0.86'
          d='M235.663 329.434C226.814 324.318 219.648 328.406 219.648 338.572V478.967C219.648 489.111 226.814 501.485 235.663 506.579L255.087 516.854C263.936 521.969 271.998 517.728 271.998 507.584V367.189C271.998 357.045 263.936 344.824 255.087 339.709L235.663 329.434Z'
          fill='url(#paint55_linear_128_10941)'
        />
        <path
          opacity='0.9'
          d='M235.773 332.101C227.317 327.226 220.457 331.117 220.457 340.824V476.497C220.457 486.204 227.317 498.031 235.773 502.906L254.366 512.744C262.822 517.619 270.272 513.618 270.272 503.912V368.238C270.272 358.531 262.822 346.792 254.366 341.917L235.773 332.079V332.101Z'
          fill='url(#paint56_linear_128_10941)'
        />
        <path
          opacity='0.95'
          d='M235.882 334.768C227.82 330.111 221.265 333.828 221.265 343.097V474.049C221.265 483.318 227.798 494.599 235.882 499.255L253.645 508.677C261.708 513.334 268.546 509.552 268.546 500.304V369.353C268.546 360.084 261.708 348.847 253.645 344.19L235.882 334.768Z'
          fill='url(#paint57_linear_128_10941)'
        />
        <path
          d='M235.991 337.435C228.3 332.997 222.073 336.539 222.073 345.371V471.6C222.073 480.432 228.3 491.166 235.991 495.604L252.924 504.589C260.615 509.027 266.842 505.464 266.842 496.653V370.424C266.842 361.592 260.615 350.858 252.924 346.42L235.991 337.435Z'
          fill='url(#paint58_linear_128_10941)'
        />
      </g>
      <path
        d='M226.771 691.004C266.99 691.004 299.594 674.374 299.594 653.861C299.594 633.347 266.99 616.718 226.771 616.718C186.552 616.718 153.948 633.347 153.948 653.861C153.948 674.374 186.552 691.004 226.771 691.004Z'
        fill='url(#paint59_radial_128_10941)'
      />
      <path
        d='M280.563 639.192H148.923V653.598C148.923 674.782 178.397 691.944 214.732 691.944C251.067 691.944 280.541 674.782 280.541 653.598C280.541 653.424 280.541 653.249 280.519 653.074H280.541V639.192H280.563Z'
        fill='url(#paint60_linear_128_10941)'
      />
      <path
        d='M214.754 677.799C251.099 677.799 280.563 660.631 280.563 639.454C280.563 618.276 251.099 601.109 214.754 601.109C178.409 601.109 148.945 618.276 148.945 639.454C148.945 660.631 178.409 677.799 214.754 677.799Z'
        fill='url(#paint61_linear_128_10941)'
      />
      <path
        d='M214.754 677.668C178.791 677.668 149.6 660.857 148.967 640C148.967 640.219 148.945 640.46 148.945 640.678C148.945 661.862 178.419 679.024 214.754 679.024C251.089 679.024 280.563 661.862 280.563 640.678C280.563 640.438 280.541 640.219 280.541 640C279.908 660.857 250.718 677.668 214.754 677.668Z'
        fill='url(#paint62_radial_128_10941)'
      />
      <path
        opacity='0.07'
        d='M214.426 695.66C178.266 695.66 148.945 678.586 148.945 657.512V667.612C148.945 688.686 178.266 705.76 214.426 705.76C250.586 705.76 279.908 688.686 279.908 667.612C279.908 667.437 279.908 667.262 279.886 667.087H279.908V657.512C279.908 678.586 250.586 695.66 214.426 695.66Z'
        fill='url(#paint63_linear_128_10941)'
      />
      <path
        d='M240.339 640.285C240.339 646.209 230.922 651.019 219.32 651.019C207.719 651.019 198.302 646.209 198.302 640.285C198.302 634.36 207.719 629.551 219.32 629.551C230.922 629.551 240.339 634.36 240.339 640.285Z'
        fill='url(#paint64_radial_128_10941)'
      />
      <path
        d='M227.317 548.16H202.212V641.815C202.212 645.859 207.828 649.139 214.776 649.139C221.724 649.139 227.339 645.859 227.339 641.815C227.339 641.771 227.339 641.749 227.339 641.706V548.16H227.317Z'
        fill='url(#paint65_linear_128_10941)'
      />
      <path
        d='M226.88 543.787C199.285 527.85 176.911 489.242 176.911 457.565V425.865L176.758 425.778L152.025 436.927V468.736C152.025 500.414 174.399 539.021 201.994 554.959C214.098 561.954 225.176 563.419 233.828 560.205V560.271L235.51 559.506C235.554 559.484 235.62 559.462 235.663 559.44L258.365 549.187C249.778 552.226 238.832 550.717 226.902 543.831L226.88 543.787Z'
        fill='url(#paint66_linear_128_10941)'
      />
      <path
        d='M251.985 547.919C251.985 556.511 238.329 563.485 221.484 563.485C204.638 563.485 190.982 556.511 190.982 547.919C190.982 539.327 204.638 532.354 221.484 532.354C238.329 532.354 251.985 539.327 251.985 547.919Z'
        fill='url(#paint67_radial_128_10941)'
      />
      <path
        d='M148.988 435.222L152.003 436.971L176.911 425.843L173.721 424.466L148.988 435.222Z'
        fill='url(#paint68_linear_128_10941)'
      />
      <path
        d='M271.758 361.155C271.758 330.753 247.131 306.107 216.753 306.107C186.375 306.107 161.748 330.753 161.748 361.155V486.138C161.748 516.54 186.375 541.186 216.753 541.186C247.131 541.186 271.758 516.54 271.758 486.138V361.155Z'
        fill='url(#paint69_linear_128_10941)'
      />
      <path
        opacity='0.5'
        d='M184.93 486.641V354.006C184.93 330.264 202.977 310.764 226.093 308.468C224.498 308.315 222.86 308.228 221.221 308.228H220.019C190.545 308.228 166.664 332.123 166.664 361.614V479.011C166.664 508.503 190.545 532.397 220.019 532.397H221.221C222.86 532.397 224.477 532.31 226.093 532.157C202.977 529.861 184.93 510.361 184.93 486.619V486.641Z'
        fill='url(#paint70_radial_128_10941)'
      />
      <path
        d='M216.742 460.473C184.864 460.473 159.017 445.41 159.017 426.849V478.224C159.017 496.807 184.864 511.848 216.742 511.848C248.62 511.848 274.467 496.785 274.467 478.224C274.467 478.071 274.467 477.918 274.445 477.765H274.467V426.871C274.467 445.454 248.62 460.494 216.742 460.494V460.473Z'
        fill='url(#paint71_linear_128_10941)'
      />
      <path
        d='M274.882 426.696V426.237H274.86C274.86 426.39 274.882 426.543 274.882 426.696Z'
        fill='url(#paint72_linear_128_10941)'
      />
      <path
        d='M232.692 561.648V561.736L234.418 560.949C233.85 561.211 233.282 561.452 232.692 561.67V561.648Z'
        fill='url(#paint73_linear_128_10941)'
      />
      <path
        d='M251.264 470.572V527.107C251.264 544.29 244.84 556.03 234.593 560.839L258.474 550.04C269.442 545.602 276.39 533.643 276.39 515.782V459.248L251.264 470.572Z'
        fill='url(#paint74_linear_128_10941)'
      />
      <path
        d='M248.248 468.845V527.129C248.248 547.591 238.613 560.315 223.078 560.315C216.546 560.315 209.139 558.019 201.645 553.691C174.29 537.885 152.025 499.474 152.025 468.058V436.993L149.01 435.244V468.037C149.01 500.457 171.908 539.961 200.137 556.27C228.366 572.579 251.264 559.528 251.264 527.107V470.572L248.248 468.824V468.845Z'
        fill='url(#paint75_linear_128_10941)'
      />
      <path
        d='M276.39 459.248L251.264 470.572L248.249 468.845L273.419 457.652L276.39 459.248Z'
        fill='url(#paint76_linear_128_10941)'
      />
      <path
        d='M272.129 417.23C272.129 420.706 272.129 424.707 272.129 424.903C272.129 442.677 247.418 457.084 216.917 457.084C186.416 457.084 161.705 442.677 161.705 424.903C161.705 424.75 161.705 420.706 161.705 417.252C160.066 420.356 159.192 423.657 159.192 427.09C159.192 445.672 185.039 460.713 216.917 460.713C248.795 460.713 274.642 445.65 274.642 427.09C274.642 423.657 273.768 420.356 272.129 417.252V417.23Z'
        fill='url(#paint77_linear_128_10941)'
      />
      <path
        d='M254.476 486.838C254.476 489.745 255.481 491.8 257.097 492.784C257.141 492.806 259.719 494.664 259.763 494.686C261.445 495.626 260.375 493.484 262.953 491.997C268.131 489.002 272.326 481.766 272.326 475.841C272.326 472.912 275.036 472.89 273.375 471.906C273.331 471.884 270.425 469.982 270.382 469.96C268.699 469.042 266.383 469.217 263.849 470.682C258.671 473.677 254.476 480.913 254.476 486.838Z'
        fill='url(#paint78_linear_128_10941)'
      />
      <path
        d='M257.381 488.805C257.381 494.751 261.576 497.134 266.755 494.139C271.933 491.144 276.128 483.908 276.128 477.984C276.128 472.059 271.933 469.654 266.755 472.649C261.576 475.644 257.381 482.881 257.381 488.805Z'
        fill='#B086CC'
      />
      <path
        d='M266.602 472.584C269.071 471.141 271.037 470.791 272.719 471.644C271.168 471.206 269.224 471.534 267.126 472.737C261.948 475.732 257.753 482.968 257.753 488.893C257.753 491.975 258.889 494.117 260.703 495.014C258.408 494.38 257.229 492.259 257.229 488.718C257.229 482.771 261.424 475.535 266.602 472.562V472.584Z'
        fill='url(#paint79_radial_128_10941)'
      />
      <path
        opacity='0.7'
        d='M217.398 379.846C241.785 379.846 261.554 364.675 261.554 345.961C261.554 327.246 241.785 312.075 217.398 312.075C193.01 312.075 173.241 327.246 173.241 345.961C173.241 364.675 193.01 379.846 217.398 379.846Z'
        fill='url(#paint80_radial_128_10941)'
      />
      <path
        d='M216.873 460.363C185.367 460.363 159.76 445.628 159.214 427.352C159.214 427.549 159.192 427.745 159.192 427.942C159.192 446.503 185.017 461.544 216.873 461.544C248.729 461.544 274.555 446.503 274.555 427.942C274.555 427.745 274.555 427.549 274.533 427.352C273.987 445.628 248.38 460.363 216.873 460.363Z'
        fill='url(#paint81_radial_128_10941)'
      />
      <path
        d='M386.597 182.742L406.72 194.372C407.484 194.809 408.118 194.46 408.118 193.585C408.118 192.711 407.506 191.639 406.72 191.18L386.597 179.55C385.832 179.113 385.198 179.462 385.198 180.337C385.198 181.211 385.81 182.283 386.597 182.742ZM406.72 198.351L386.597 186.721C385.832 186.283 385.198 186.633 385.198 187.508C385.198 188.382 385.81 189.453 386.597 189.912L406.72 201.543C407.484 201.98 408.118 201.63 408.118 200.756C408.118 199.881 407.506 198.81 406.72 198.351ZM406.72 205.522L386.597 193.891C385.832 193.454 385.198 193.804 385.198 194.678C385.198 195.553 385.81 196.624 386.597 197.083L406.72 208.713C407.484 209.151 408.118 208.801 408.118 207.926C408.118 207.052 407.506 205.981 406.72 205.522Z'
        fill='white'
      />
      <path
        d='M263.237 113.156C263.237 115.277 261.729 116.129 259.894 115.058C258.037 113.987 256.551 111.407 256.551 109.265C256.551 107.122 258.059 106.292 259.894 107.363C261.751 108.434 263.237 111.014 263.237 113.156Z'
        fill='white'
      />
      <path
        d='M253.121 107.275C253.121 109.396 251.613 110.248 249.778 109.177C247.921 108.106 246.435 105.526 246.435 103.384C246.435 101.241 247.943 100.411 249.778 101.482C251.635 102.553 253.121 105.133 253.121 107.275Z'
        fill='white'
      />
      <path
        d='M243.005 101.394C243.005 103.515 241.497 104.368 239.662 103.296C237.805 102.225 236.319 99.6456 236.319 97.5031C236.319 95.3607 237.826 94.5299 239.662 95.6012C241.519 96.6724 243.005 99.2521 243.005 101.394Z'
        fill='white'
      />
      <g opacity='0.5'>
        <path
          d='M322.36 136.985C309.841 129.749 299.703 135.542 299.703 149.906C299.703 164.269 308.967 180.206 320.7 187.967V200.756L330.51 191.967C338.987 193.104 345.018 187.158 345.018 176.096C345.018 161.733 334.88 144.221 322.36 136.985ZM316.395 148.922C316.395 145.162 319.083 143.631 322.36 145.511C325.637 147.413 328.325 152.048 328.325 155.808V166.345C328.325 170.106 325.637 171.636 322.36 169.756C319.083 167.854 316.395 163.219 316.395 159.459V148.922ZM332.083 168.51C332.083 174.085 328.5 176.62 323.868 174.762V179.812C325.047 181.037 325.856 182.676 325.856 184.053L318.886 180.031C318.886 178.675 319.694 177.976 320.874 178.107V173.057C316.22 169.537 312.659 162.869 312.659 157.295V154.649C312.659 154.016 313.096 153.775 313.664 154.081C314.21 154.409 314.647 155.174 314.647 155.786V158.432C314.647 163.329 318.121 169.297 322.382 171.767C326.642 174.238 330.116 172.248 330.116 167.351V164.706C330.116 164.072 330.553 163.831 331.122 164.137C331.668 164.465 332.105 165.231 332.105 165.843V168.488L332.083 168.51Z'
          fill='#9F19AB'
        />
      </g>
      <path
        d='M300.883 152.551C300.883 138.188 311.021 132.394 323.54 139.631C330.816 143.828 337.283 151.523 341.435 160.049C337.414 150.496 330.379 141.62 322.339 136.985C309.819 129.749 299.681 135.542 299.681 149.906C299.681 155.917 301.451 162.476 304.444 168.597C302.194 163.263 300.883 157.688 300.883 152.529V152.551Z'
        fill='url(#paint82_radial_128_10941)'
      />
      <g opacity='0.5'>
        <path
          d='M314.101 301.713L318.69 304.38V333.172L326.25 337.544V342.354L314.08 335.336V301.735L314.101 301.713Z'
          fill='#9F19AB'
        />
        <path
          d='M328.478 310.02L333.066 312.688V346.289L328.478 343.622V310.02Z'
          fill='#9F19AB'
        />
        <path
          d='M335.295 313.955L339.927 316.644L342.942 344.452L343.029 344.496L346.045 320.164L350.261 322.613L345.826 353.656L339.73 350.137L335.295 313.977V313.955Z'
          fill='#9F19AB'
        />
        <path
          d='M352.468 323.881L365.01 331.117V335.926L357.057 331.335V340.211L363.371 343.862V348.672L357.057 345.021V355.34L365.01 359.931V364.74L352.468 357.504V323.902V323.881Z'
          fill='#9F19AB'
        />
      </g>
    </g>
    <defs>
      <radialGradient
        id='paint0_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(245.4 382.71) scale(245.42 353.821)'
      >
        <stop stopColor='#DC3DAA' />
        <stop offset='0.11' stopColor='#B5328C' />
        <stop offset='0.28' stopColor='#7E2361' />
        <stop offset='0.45' stopColor='#51163E' />
        <stop offset='0.61' stopColor='#2D0C23' />
        <stop offset='0.76' stopColor='#14050F' />
        <stop offset='0.89' stopColor='#050104' />
        <stop offset='1' />
      </radialGradient>
      <linearGradient
        id='paint1_linear_128_10941'
        x1='194.566'
        y1='74.1547'
        x2='395.818'
        y2='8.3544'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#9F79C8' />
        <stop offset='0.14' stopColor='#A27CC9' />
        <stop offset='0.27' stopColor='#AB87CE' />
        <stop offset='0.4' stopColor='#BB9AD7' />
        <stop offset='0.52' stopColor='#D1B4E3' />
        <stop offset='0.65' stopColor='#DAC3E8' />
        <stop offset='0.9' stopColor='#F3ECF8' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_128_10941'
        x1='164.436'
        y1='166.455'
        x2='208.931'
        y2='107.059'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#C1A1D5' />
        <stop offset='0.05' stopColor='#C7ABD9' />
        <stop offset='0.11' stopColor='#D9C6E5' />
        <stop offset='0.2' stopColor='#F6F2F9' />
        <stop offset='0.22' stopColor='white' />
        <stop offset='0.25' stopColor='#F9F5FB' />
        <stop offset='0.3' stopColor='#E9DBF1' />
        <stop offset='0.36' stopColor='#D1B4E3' />
        <stop offset='0.43' stopColor='#B294D5' />
        <stop offset='0.49' stopColor='#9B7CCB' />
        <stop offset='0.56' stopColor='#8D6EC5' />
        <stop offset='0.61' stopColor='#8969C3' />
        <stop offset='0.76' stopColor='#9371C5' />
        <stop offset='1' stopColor='#B086CC' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_128_10941'
        x1='171.274'
        y1='212.67'
        x2='233.6'
        y2='95.3685'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C211A1' />
        <stop offset='0.05' stopColor='#C518A4' />
        <stop offset='0.13' stopColor='#CD2CAF' />
        <stop offset='0.14' stopColor='#CF30B1' />
        <stop offset='0.23' stopColor='#FF63CE' />
        <stop offset='0.28' stopColor='#EB48BF' />
        <stop offset='0.38' stopColor='#C211A1' />
        <stop offset='0.54' stopColor='#A919AB' />
        <stop offset='0.62' stopColor='#AC1BAA' />
        <stop offset='0.7' stopColor='#B722AA' />
        <stop offset='0.77' stopColor='#C82FAA' />
        <stop offset='0.82' stopColor='#DC3DAA' />
        <stop offset='0.93' stopColor='#CD23A4' />
        <stop offset='1' stopColor='#C211A1' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_128_10941'
        x1='179.861'
        y1='229.001'
        x2='255.656'
        y2='94.9609'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.06' stopColor='#200041' />
        <stop offset='0.11' stopColor='#210143' />
        <stop offset='0.14' stopColor='#25084A' />
        <stop offset='0.16' stopColor='#2C1357' />
        <stop offset='0.17' stopColor='#311B61' />
        <stop offset='0.19' stopColor='#351F65' />
        <stop offset='0.21' stopColor='#422C72' />
        <stop offset='0.23' stopColor='#574188' />
        <stop offset='0.25' stopColor='#755FA6' />
        <stop offset='0.26' stopColor='#8770B8' />
        <stop offset='0.28' stopColor='#7D66AE' />
        <stop offset='0.35' stopColor='#5C458C' />
        <stop offset='0.41' stopColor='#442E74' />
        <stop offset='0.47' stopColor='#351F66' />
        <stop offset='0.52' stopColor='#311B61' />
        <stop offset='0.59' stopColor='#2A1054' />
        <stop offset='0.71' stopColor='#220446' />
        <stop offset='0.81' stopColor='#200041' />
        <stop offset='1' stopColor='#200041' />
      </linearGradient>
      <linearGradient
        id='paint5_linear_128_10941'
        x1='178.3'
        y1='170.142'
        x2='287.108'
        y2='170.142'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#311B61' />
        <stop offset='0.2' stopColor='#290E52' />
        <stop offset='0.49' stopColor='#200041' />
        <stop offset='1' stopColor='#0F001F' />
      </linearGradient>
      <linearGradient
        id='paint6_linear_128_10941'
        x1='318.17'
        y1='233.39'
        x2='218.958'
        y2='161.804'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#311B61' />
        <stop offset='0.2' stopColor='#290E52' />
        <stop offset='0.49' stopColor='#200041' />
        <stop offset='1' stopColor='#0F001F' />
      </linearGradient>
      <radialGradient
        id='paint7_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(99.3679 -2.94588) rotate(-147.115) scale(21.5315 24.0918)'
      >
        <stop stopColor='#972A74' />
        <stop offset='0.04' stopColor='#8A266A' />
        <stop offset='0.19' stopColor='#601A49' />
        <stop offset='0.35' stopColor='#3D112F' />
        <stop offset='0.51' stopColor='#22091A' />
        <stop offset='0.67' stopColor='#0F040B' />
        <stop offset='0.83' stopColor='#030102' />
        <stop offset='1' />
      </radialGradient>
      <radialGradient
        id='paint8_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(222.379 168.466) rotate(180) scale(58.3587 58.3925)'
      >
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <radialGradient
        id='paint9_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(-3047.07 2089.66) rotate(-30.0144) scale(67.0643 57.692)'
      >
        <stop offset='0.55' stopColor='#311B61' />
        <stop offset='0.59' stopColor='#372165' />
        <stop offset='0.65' stopColor='#473472' />
        <stop offset='0.72' stopColor='#635287' />
        <stop offset='0.81' stopColor='#897DA5' />
        <stop offset='0.89' stopColor='#BBB3CA' />
        <stop offset='0.99' stopColor='#F6F5F8' />
        <stop offset='1' stopColor='white' />
      </radialGradient>
      <linearGradient
        id='paint10_linear_128_10941'
        x1='172.804'
        y1='68.1429'
        x2='384.748'
        y2='435.028'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.06' stopColor='#200041' />
        <stop offset='0.26' stopColor='#210143' />
        <stop offset='0.36' stopColor='#25084A' />
        <stop offset='0.43' stopColor='#2C1357' />
        <stop offset='0.47' stopColor='#311B61' />
        <stop offset='0.48' stopColor='#351F65' />
        <stop offset='0.5' stopColor='#422C72' />
        <stop offset='0.51' stopColor='#574188' />
        <stop offset='0.52' stopColor='#755FA6' />
        <stop offset='0.53' stopColor='#8770B8' />
        <stop offset='0.54' stopColor='#7D66AE' />
        <stop offset='0.56' stopColor='#5C458C' />
        <stop offset='0.59' stopColor='#442E74' />
        <stop offset='0.61' stopColor='#351F66' />
        <stop offset='0.63' stopColor='#311B61' />
        <stop offset='0.67' stopColor='#2A1054' />
        <stop offset='0.75' stopColor='#220446' />
        <stop offset='0.81' stopColor='#200041' />
        <stop offset='1' stopColor='#200041' />
      </linearGradient>
      <radialGradient
        id='paint11_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(501.893 217.239) scale(397.39 397.62)'
      >
        <stop stopColor='#711F56' />
        <stop offset='0.16' stopColor='#6B1D51' />
        <stop offset='0.36' stopColor='#5C1946' />
        <stop offset='0.58' stopColor='#421232' />
        <stop offset='0.82' stopColor='#1F0818' />
        <stop offset='1' />
      </radialGradient>
      <radialGradient
        id='paint12_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(435.407 188.841) scale(234.112 234.248)'
      >
        <stop stopColor='#AF8AFC' />
        <stop offset='1' stopColor='#10054D' />
      </radialGradient>
      <linearGradient
        id='paint13_linear_128_10941'
        x1='462.718'
        y1='657.206'
        x2='133.733'
        y2='-37.5422'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#311B61' />
        <stop offset='0.2' stopColor='#290E52' />
        <stop offset='0.49' stopColor='#200041' />
        <stop offset='1' stopColor='#0F001F' />
      </linearGradient>
      <linearGradient
        id='paint14_linear_128_10941'
        x1='221.833'
        y1='366.096'
        x2='428.219'
        y2='366.096'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#311B61' />
        <stop offset='0.2' stopColor='#290E52' />
        <stop offset='0.49' stopColor='#200041' />
        <stop offset='1' stopColor='#0F001F' />
      </linearGradient>
      <linearGradient
        id='paint15_linear_128_10941'
        x1='176.016'
        y1='81.7846'
        x2='490.337'
        y2='680.776'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint16_linear_128_10941'
        x1='240.929'
        y1='317.847'
        x2='428.747'
        y2='718.444'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <radialGradient
        id='paint17_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(325.026 404.66) scale(85.2767 85.3261)'
      >
        <stop stopColor='#711F56' />
        <stop offset='0.16' stopColor='#6B1D51' />
        <stop offset='0.36' stopColor='#5C1946' />
        <stop offset='0.58' stopColor='#421232' />
        <stop offset='0.82' stopColor='#1F0818' />
        <stop offset='1' />
      </radialGradient>
      <linearGradient
        id='paint18_linear_128_10941'
        x1='6166.82'
        y1='-15897.1'
        x2='6148.31'
        y2='-15843.7'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#311B61' />
        <stop offset='0.2' stopColor='#290E52' />
        <stop offset='0.49' stopColor='#200041' />
        <stop offset='1' stopColor='#0F001F' />
      </linearGradient>
      <linearGradient
        id='paint19_linear_128_10941'
        x1='458.108'
        y1='298.915'
        x2='432.654'
        y2='297.801'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#3D065F' />
        <stop offset='1' stopColor='#5F27BD' />
      </linearGradient>
      <radialGradient
        id='paint20_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(445.305 288.028) scale(14.9448 14.9534)'
      >
        <stop stopColor='#711F56' />
        <stop offset='0.16' stopColor='#6B1D51' />
        <stop offset='0.36' stopColor='#5C1946' />
        <stop offset='0.58' stopColor='#421232' />
        <stop offset='0.82' stopColor='#1F0818' />
        <stop offset='1' />
      </radialGradient>
      <radialGradient
        id='paint21_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(-2784.07 2143.68) rotate(-30.0144) scale(67.0643 57.692)'
      >
        <stop offset='0.55' stopColor='#311B61' />
        <stop offset='0.59' stopColor='#372165' />
        <stop offset='0.65' stopColor='#473472' />
        <stop offset='0.72' stopColor='#635287' />
        <stop offset='0.81' stopColor='#897DA5' />
        <stop offset='0.89' stopColor='#BBB3CA' />
        <stop offset='0.99' stopColor='#F6F5F8' />
        <stop offset='1' stopColor='white' />
      </radialGradient>
      <linearGradient
        id='paint22_linear_128_10941'
        x1='389.918'
        y1='343.141'
        x2='466.084'
        y2='210.172'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.06' stopColor='#200041' />
        <stop offset='0.11' stopColor='#210143' />
        <stop offset='0.14' stopColor='#25084A' />
        <stop offset='0.16' stopColor='#2C1357' />
        <stop offset='0.17' stopColor='#311B61' />
        <stop offset='0.19' stopColor='#351F65' />
        <stop offset='0.21' stopColor='#422C72' />
        <stop offset='0.23' stopColor='#574188' />
        <stop offset='0.25' stopColor='#755FA6' />
        <stop offset='0.26' stopColor='#8770B8' />
        <stop offset='0.28' stopColor='#7D66AE' />
        <stop offset='0.35' stopColor='#5C458C' />
        <stop offset='0.41' stopColor='#442E74' />
        <stop offset='0.47' stopColor='#351F66' />
        <stop offset='0.52' stopColor='#311B61' />
        <stop offset='0.59' stopColor='#2A1054' />
        <stop offset='0.71' stopColor='#220446' />
        <stop offset='0.81' stopColor='#200041' />
        <stop offset='1' stopColor='#200041' />
      </linearGradient>
      <linearGradient
        id='paint23_linear_128_10941'
        x1='388.461'
        y1='284.998'
        x2='497.269'
        y2='284.998'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#311B61' />
        <stop offset='0.2' stopColor='#290E52' />
        <stop offset='0.49' stopColor='#200041' />
        <stop offset='1' stopColor='#0F001F' />
      </linearGradient>
      <radialGradient
        id='paint24_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(311.048 105.671) rotate(-147.105) scale(21.5315 24.0918)'
      >
        <stop stopColor='#972A74' />
        <stop offset='0.04' stopColor='#8A266A' />
        <stop offset='0.19' stopColor='#601A49' />
        <stop offset='0.35' stopColor='#3D112F' />
        <stop offset='0.51' stopColor='#22091A' />
        <stop offset='0.67' stopColor='#0F040B' />
        <stop offset='0.83' stopColor='#030102' />
        <stop offset='1' />
      </radialGradient>
      <radialGradient
        id='paint25_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(432.523 283.305) rotate(180) scale(58.3587 58.3925)'
      >
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <linearGradient
        id='paint26_linear_128_10941'
        x1='415.721'
        y1='346.07'
        x2='480.233'
        y2='229.142'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#C211A1' />
        <stop offset='0.05' stopColor='#C518A4' />
        <stop offset='0.13' stopColor='#CD2CAF' />
        <stop offset='0.14' stopColor='#CF30B1' />
        <stop offset='0.23' stopColor='#FF63CE' />
        <stop offset='0.28' stopColor='#EB48BF' />
        <stop offset='0.38' stopColor='#C211A1' />
        <stop offset='0.54' stopColor='#A919AB' />
        <stop offset='0.62' stopColor='#AC1BAA' />
        <stop offset='0.7' stopColor='#B722AA' />
        <stop offset='0.77' stopColor='#C82FAA' />
        <stop offset='0.82' stopColor='#DC3DAA' />
        <stop offset='0.93' stopColor='#CD23A4' />
        <stop offset='1' stopColor='#C211A1' />
      </linearGradient>
      <linearGradient
        id='paint27_linear_128_10941'
        x1='431.057'
        y1='287.362'
        x2='587.531'
        y2='331.519'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.04' stopColor='#9F19AB' />
        <stop offset='0.24' stopColor='#AE24B0' />
        <stop offset='0.65' stopColor='#D643BF' />
        <stop offset='1' stopColor='#FF63CE' />
      </linearGradient>
      <radialGradient
        id='paint28_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(457.671 295.963) rotate(180) scale(50.9301 50.9595)'
      >
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <linearGradient
        id='paint29_linear_128_10941'
        x1='449.063'
        y1='332.516'
        x2='511.386'
        y2='227.065'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#C1A1D5' />
        <stop offset='0.05' stopColor='#C7ABD9' />
        <stop offset='0.11' stopColor='#D9C6E5' />
        <stop offset='0.2' stopColor='#F6F2F9' />
        <stop offset='0.22' stopColor='white' />
        <stop offset='0.25' stopColor='#F9F5FB' />
        <stop offset='0.3' stopColor='#E9DBF1' />
        <stop offset='0.36' stopColor='#D1B4E3' />
        <stop offset='0.43' stopColor='#B294D5' />
        <stop offset='0.49' stopColor='#9B7CCB' />
        <stop offset='0.56' stopColor='#8D6EC5' />
        <stop offset='0.61' stopColor='#8969C3' />
        <stop offset='0.76' stopColor='#9371C5' />
        <stop offset='1' stopColor='#B086CC' />
      </linearGradient>
      <radialGradient
        id='paint30_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(471.109 303.396) rotate(180) scale(34.1718 34.1917)'
      >
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <linearGradient
        id='paint31_linear_128_10941'
        x1='433.629'
        y1='358.544'
        x2='558.25'
        y2='217.236'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#9F79C8' />
        <stop offset='0.14' stopColor='#A27CC9' />
        <stop offset='0.27' stopColor='#AB87CE' />
        <stop offset='0.4' stopColor='#BB9AD7' />
        <stop offset='0.52' stopColor='#D1B4E3' />
        <stop offset='0.65' stopColor='#DAC3E8' />
        <stop offset='0.9' stopColor='#F3ECF8' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <radialGradient
        id='paint32_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(475.675 305.276) rotate(180) scale(24.0995 24.1134)'
      >
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <radialGradient
        id='paint33_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(209.161 26.5183) scale(0.218703)'
      >
        <stop offset='0.55' stopColor='#311B61' />
        <stop offset='0.59' stopColor='#372165' />
        <stop offset='0.65' stopColor='#473472' />
        <stop offset='0.72' stopColor='#635287' />
        <stop offset='0.81' stopColor='#897DA5' />
        <stop offset='0.89' stopColor='#BBB3CA' />
        <stop offset='0.99' stopColor='#F6F5F8' />
        <stop offset='1' stopColor='white' />
      </radialGradient>
      <linearGradient
        id='paint34_linear_128_10941'
        x1='263.892'
        y1='-28.0266'
        x2='467.55'
        y2='256.435'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#C1A1D5' />
        <stop offset='0.05' stopColor='#C7ABD9' />
        <stop offset='0.11' stopColor='#D9C6E5' />
        <stop offset='0.2' stopColor='#F6F2F9' />
        <stop offset='0.22' stopColor='white' />
        <stop offset='0.25' stopColor='#F9F5FB' />
        <stop offset='0.3' stopColor='#E9DBF1' />
        <stop offset='0.36' stopColor='#D1B4E3' />
        <stop offset='0.43' stopColor='#B294D5' />
        <stop offset='0.49' stopColor='#9B7CCB' />
        <stop offset='0.56' stopColor='#8D6EC5' />
        <stop offset='0.61' stopColor='#8969C3' />
        <stop offset='0.76' stopColor='#9371C5' />
        <stop offset='1' stopColor='#B086CC' />
      </linearGradient>
      <linearGradient
        id='paint35_linear_128_10941'
        x1='206.495'
        y1='254.382'
        x2='392.489'
        y2='110.615'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#9F79C8' />
        <stop offset='0.14' stopColor='#A27CC9' />
        <stop offset='0.27' stopColor='#AB87CE' />
        <stop offset='0.4' stopColor='#BB9AD7' />
        <stop offset='0.52' stopColor='#D1B4E3' />
        <stop offset='0.73' stopColor='#DAC3E8' />
        <stop offset='1' stopColor='#EBDFF3' />
      </linearGradient>
      <radialGradient
        id='paint36_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(202.409 68.9518) scale(32.3147 32.3334)'
      >
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <radialGradient
        id='paint37_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(339.599 149.993) scale(127.686 127.76)'
      >
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <linearGradient
        id='paint38_linear_128_10941'
        x1='125.108'
        y1='191.771'
        x2='285.406'
        y2='497.255'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint39_linear_128_10941'
        x1='127.598'
        y1='196.602'
        x2='284.245'
        y2='495.136'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint40_linear_128_10941'
        x1='130.089'
        y1='201.455'
        x2='283.106'
        y2='493.039'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint41_linear_128_10941'
        x1='132.58'
        y1='206.287'
        x2='281.944'
        y2='490.92'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint42_linear_128_10941'
        x1='135.071'
        y1='211.14'
        x2='280.783'
        y2='488.823'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint43_linear_128_10941'
        x1='137.583'
        y1='215.993'
        x2='279.622'
        y2='486.704'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint44_linear_128_10941'
        x1='140.074'
        y1='220.825'
        x2='278.482'
        y2='484.607'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint45_linear_128_10941'
        x1='142.565'
        y1='225.678'
        x2='277.321'
        y2='482.488'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint46_linear_128_10941'
        x1='145.056'
        y1='230.509'
        x2='276.16'
        y2='480.37'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint47_linear_128_10941'
        x1='147.546'
        y1='235.363'
        x2='275.02'
        y2='478.273'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint48_linear_128_10941'
        x1='150.059'
        y1='240.216'
        x2='273.859'
        y2='476.154'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint49_linear_128_10941'
        x1='152.55'
        y1='245.047'
        x2='272.719'
        y2='474.057'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint50_linear_128_10941'
        x1='155.041'
        y1='249.901'
        x2='271.558'
        y2='471.938'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint51_linear_128_10941'
        x1='157.532'
        y1='254.732'
        x2='270.397'
        y2='469.819'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint52_linear_128_10941'
        x1='160.044'
        y1='259.586'
        x2='269.257'
        y2='467.722'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint53_linear_128_10941'
        x1='162.535'
        y1='264.417'
        x2='268.118'
        y2='465.603'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint54_linear_128_10941'
        x1='165.047'
        y1='269.248'
        x2='266.956'
        y2='463.484'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint55_linear_128_10941'
        x1='167.538'
        y1='274.102'
        x2='265.817'
        y2='461.387'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint56_linear_128_10941'
        x1='170.051'
        y1='278.933'
        x2='264.677'
        y2='459.269'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint57_linear_128_10941'
        x1='172.564'
        y1='283.765'
        x2='263.538'
        y2='457.15'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <linearGradient
        id='paint58_linear_128_10941'
        x1='175.076'
        y1='288.618'
        x2='262.398'
        y2='455.031'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#9F19AB' />
        <stop offset='0.43' stopColor='#5F27BD' />
        <stop offset='0.9' stopColor='#3D065F' />
      </linearGradient>
      <radialGradient
        id='paint59_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(224.805 668.639) scale(72.7563 37.0162)'
      >
        <stop offset='0.19' stopColor='#5F5C5D' />
        <stop offset='1' stopColor='white' />
      </radialGradient>
      <linearGradient
        id='paint60_linear_128_10941'
        x1='149.556'
        y1='665.579'
        x2='279.121'
        y2='665.579'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#C1A1D5' />
        <stop offset='0.05' stopColor='#C7ABD9' />
        <stop offset='0.11' stopColor='#D9C6E5' />
        <stop offset='0.2' stopColor='#F6F2F9' />
        <stop offset='0.22' stopColor='white' />
        <stop offset='0.25' stopColor='#F9F5FB' />
        <stop offset='0.3' stopColor='#E9DBF1' />
        <stop offset='0.36' stopColor='#D1B4E3' />
        <stop offset='0.43' stopColor='#B294D5' />
        <stop offset='0.49' stopColor='#9B7CCB' />
        <stop offset='0.56' stopColor='#8D6EC5' />
        <stop offset='0.61' stopColor='#8969C3' />
        <stop offset='0.76' stopColor='#9371C5' />
        <stop offset='1' stopColor='#B086CC' />
      </linearGradient>
      <linearGradient
        id='paint61_linear_128_10941'
        x1='292.034'
        y1='556.533'
        x2='187.706'
        y2='668.36'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#9F79C8' />
        <stop offset='0.14' stopColor='#A47FCA' />
        <stop offset='0.33' stopColor='#B491D3' />
        <stop offset='0.55' stopColor='#CDAFE1' />
        <stop offset='0.58' stopColor='#D1B4E3' />
        <stop offset='0.76' stopColor='#DAC3E8' />
        <stop offset='1' stopColor='#EBDEF3' />
      </linearGradient>
      <radialGradient
        id='paint62_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(193.626 672.531) scale(48.5485 48.5766)'
      >
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <linearGradient
        id='paint63_linear_128_10941'
        x1='149.557'
        y1='681.625'
        x2='278.444'
        y2='681.625'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#C1A1D5' />
        <stop offset='0.05' stopColor='#C7ABD9' />
        <stop offset='0.11' stopColor='#D9C6E5' />
        <stop offset='0.2' stopColor='#F6F2F9' />
        <stop offset='0.22' stopColor='white' />
        <stop offset='0.25' stopColor='#F9F5FB' />
        <stop offset='0.3' stopColor='#E9DBF1' />
        <stop offset='0.36' stopColor='#D1B4E3' />
        <stop offset='0.43' stopColor='#B294D5' />
        <stop offset='0.49' stopColor='#9B7CCB' />
        <stop offset='0.56' stopColor='#8D6EC5' />
        <stop offset='0.61' stopColor='#8969C3' />
        <stop offset='0.76' stopColor='#9371C5' />
        <stop offset='1' stopColor='#B086CC' />
      </linearGradient>
      <radialGradient
        id='paint64_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(217.355 655.102) scale(21.0122 10.6904)'
      >
        <stop offset='0.19' stopColor='#C7BCD6' />
        <stop offset='1' stopColor='white' />
      </radialGradient>
      <linearGradient
        id='paint65_linear_128_10941'
        x1='202.322'
        y1='598.638'
        x2='227.033'
        y2='598.638'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#C1A1D5' />
        <stop offset='0.05' stopColor='#C7ABD9' />
        <stop offset='0.11' stopColor='#D9C6E5' />
        <stop offset='0.2' stopColor='#F6F2F9' />
        <stop offset='0.22' stopColor='white' />
        <stop offset='0.25' stopColor='#F9F5FB' />
        <stop offset='0.3' stopColor='#E9DBF1' />
        <stop offset='0.36' stopColor='#D1B4E3' />
        <stop offset='0.43' stopColor='#B294D5' />
        <stop offset='0.49' stopColor='#9B7CCB' />
        <stop offset='0.56' stopColor='#8D6EC5' />
        <stop offset='0.61' stopColor='#8969C3' />
        <stop offset='0.76' stopColor='#9371C5' />
        <stop offset='1' stopColor='#B086CC' />
      </linearGradient>
      <linearGradient
        id='paint66_linear_128_10941'
        x1='229.174'
        y1='572.798'
        x2='167.106'
        y2='430.33'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8D6CC4' />
        <stop offset='0.06' stopColor='#A27CC9' />
        <stop offset='0.16' stopColor='#B090D3' />
        <stop offset='0.32' stopColor='#9B78C8' />
        <stop offset='1' stopColor='#B086CC' />
      </linearGradient>
      <radialGradient
        id='paint67_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(219.52 563.074) scale(30.4741 15.5043)'
      >
        <stop offset='0.19' stopColor='#C7BCD6' />
        <stop offset='1' stopColor='white' />
      </radialGradient>
      <linearGradient
        id='paint68_linear_128_10941'
        x1='165.594'
        y1='396.855'
        x2='161.897'
        y2='443.857'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#9F79C8' />
        <stop offset='0.14' stopColor='#A27CC9' />
        <stop offset='0.27' stopColor='#AB87CE' />
        <stop offset='0.4' stopColor='#BB9AD7' />
        <stop offset='0.52' stopColor='#D1B4E3' />
        <stop offset='0.65' stopColor='#DAC3E8' />
        <stop offset='0.9' stopColor='#F3ECF8' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint69_linear_128_10941'
        x1='162.272'
        y1='423.657'
        x2='270.534'
        y2='423.657'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#25054D' />
        <stop offset='0.22' stopColor='#45108A' />
        <stop offset='0.42' stopColor='#25054D' />
        <stop offset='0.56' stopColor='#270551' />
        <stop offset='0.7' stopColor='#2D085D' />
        <stop offset='0.84' stopColor='#380B72' />
        <stop offset='0.96' stopColor='#45108A' />
      </linearGradient>
      <radialGradient
        id='paint70_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(192.73 368.588) scale(121.896 121.966)'
      >
        <stop stopColor='#972A74' />
        <stop offset='0.04' stopColor='#8A266A' />
        <stop offset='0.19' stopColor='#601A49' />
        <stop offset='0.35' stopColor='#3D112F' />
        <stop offset='0.51' stopColor='#22091A' />
        <stop offset='0.67' stopColor='#0F040B' />
        <stop offset='0.83' stopColor='#030102' />
        <stop offset='1' />
      </radialGradient>
      <linearGradient
        id='paint71_linear_128_10941'
        x1='159.585'
        y1='469.348'
        x2='273.2'
        y2='469.348'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#C1A1D5' />
        <stop offset='0.05' stopColor='#C7ABD9' />
        <stop offset='0.11' stopColor='#D9C6E5' />
        <stop offset='0.2' stopColor='#F6F2F9' />
        <stop offset='0.22' stopColor='white' />
        <stop offset='0.25' stopColor='#F9F5FB' />
        <stop offset='0.3' stopColor='#E9DBF1' />
        <stop offset='0.36' stopColor='#D1B4E3' />
        <stop offset='0.43' stopColor='#B294D5' />
        <stop offset='0.49' stopColor='#9B7CCB' />
        <stop offset='0.56' stopColor='#8D6EC5' />
        <stop offset='0.61' stopColor='#8969C3' />
        <stop offset='0.76' stopColor='#9371C5' />
        <stop offset='1' stopColor='#B086CC' />
      </linearGradient>
      <linearGradient
        id='paint72_linear_128_10941'
        x1='274.838'
        y1='426.456'
        x2='274.86'
        y2='426.456'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#C1A1D5' />
        <stop offset='0.05' stopColor='#C7ABD9' />
        <stop offset='0.11' stopColor='#D9C6E5' />
        <stop offset='0.2' stopColor='#F6F2F9' />
        <stop offset='0.22' stopColor='white' />
        <stop offset='0.25' stopColor='#F9F5FB' />
        <stop offset='0.3' stopColor='#E9DBF1' />
        <stop offset='0.36' stopColor='#D1B4E3' />
        <stop offset='0.43' stopColor='#B294D5' />
        <stop offset='0.49' stopColor='#9B7CCB' />
        <stop offset='0.56' stopColor='#8D6EC5' />
        <stop offset='0.61' stopColor='#8969C3' />
        <stop offset='0.76' stopColor='#9371C5' />
        <stop offset='1' stopColor='#B086CC' />
      </linearGradient>
      <linearGradient
        id='paint73_linear_128_10941'
        x1='232.714'
        y1='561.32'
        x2='234.396'
        y2='561.32'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#25054D' />
        <stop offset='0.22' stopColor='#45108A' />
        <stop offset='0.42' stopColor='#25054D' />
        <stop offset='0.56' stopColor='#270551' />
        <stop offset='0.7' stopColor='#2D085D' />
        <stop offset='0.84' stopColor='#380B72' />
        <stop offset='0.96' stopColor='#45108A' />
      </linearGradient>
      <linearGradient
        id='paint74_linear_128_10941'
        x1='271.583'
        y1='556.729'
        x2='227.993'
        y2='430.538'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#8D6CC4' />
        <stop offset='0.06' stopColor='#A27CC9' />
        <stop offset='0.16' stopColor='#B090D3' />
        <stop offset='0.32' stopColor='#9B78C8' />
        <stop offset='1' stopColor='#B086CC' />
      </linearGradient>
      <linearGradient
        id='paint75_linear_128_10941'
        x1='91.7221'
        y1='642.165'
        x2='250.993'
        y2='417.448'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#9F79C8' />
        <stop offset='0.14' stopColor='#A27CC9' />
        <stop offset='0.27' stopColor='#AB87CE' />
        <stop offset='0.4' stopColor='#BB9AD7' />
        <stop offset='0.52' stopColor='#D1B4E3' />
        <stop offset='0.65' stopColor='#DAC3E8' />
        <stop offset='0.9' stopColor='#F3ECF8' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint76_linear_128_10941'
        x1='276.652'
        y1='427.745'
        x2='256.356'
        y2='479.025'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#9F79C8' />
        <stop offset='0.14' stopColor='#A27CC9' />
        <stop offset='0.27' stopColor='#AB87CE' />
        <stop offset='0.4' stopColor='#BB9AD7' />
        <stop offset='0.52' stopColor='#D1B4E3' />
        <stop offset='0.65' stopColor='#DAC3E8' />
        <stop offset='0.9' stopColor='#F3ECF8' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
      <linearGradient
        id='paint77_linear_128_10941'
        x1='247.833'
        y1='371.998'
        x2='195.59'
        y2='465.037'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#9F79C8' />
        <stop offset='0.14' stopColor='#A47FCA' />
        <stop offset='0.33' stopColor='#B491D3' />
        <stop offset='0.55' stopColor='#CDAFE1' />
        <stop offset='0.58' stopColor='#D1B4E3' />
        <stop offset='0.76' stopColor='#DAC3E8' />
        <stop offset='1' stopColor='#EBDEF3' />
      </linearGradient>
      <linearGradient
        id='paint78_linear_128_10941'
        x1='257.622'
        y1='491.144'
        x2='276.056'
        y2='459.957'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.01' stopColor='#C1A1D5' />
        <stop offset='0.05' stopColor='#C7ABD9' />
        <stop offset='0.11' stopColor='#D9C6E5' />
        <stop offset='0.2' stopColor='#F6F2F9' />
        <stop offset='0.22' stopColor='white' />
        <stop offset='0.25' stopColor='#F9F5FB' />
        <stop offset='0.3' stopColor='#E9DBF1' />
        <stop offset='0.36' stopColor='#D1B4E3' />
        <stop offset='0.43' stopColor='#B294D5' />
        <stop offset='0.49' stopColor='#9B7CCB' />
        <stop offset='0.56' stopColor='#8D6EC5' />
        <stop offset='0.61' stopColor='#8969C3' />
        <stop offset='0.76' stopColor='#9371C5' />
        <stop offset='1' stopColor='#B086CC' />
      </linearGradient>
      <radialGradient
        id='paint79_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(264.963 483.121) rotate(180) scale(10.0505 10.0564)'
      >
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <radialGradient
        id='paint80_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(203.022 326.517) scale(77.9398 59.9884)'
      >
        <stop offset='0.11' stopColor='#972A74' />
        <stop offset='0.17' stopColor='#79215D' />
        <stop offset='0.27' stopColor='#541740' />
        <stop offset='0.37' stopColor='#350E29' />
        <stop offset='0.47' stopColor='#1E0817' />
        <stop offset='0.58' stopColor='#0D030A' />
        <stop offset='0.71' stopColor='#030002' />
        <stop offset='0.86' />
      </radialGradient>
      <radialGradient
        id='paint81_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(198.367 455.86) scale(42.54 42.5647)'
      >
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <radialGradient
        id='paint82_radial_128_10941'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(314.604 141.205) scale(19.1834 19.1946)'
      >
        <stop stopColor='white' />
        <stop offset='1' />
      </radialGradient>
      <clipPath id='clip0_128_10941'>
        <rect width='536' height='733' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
